var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { flex: "main:center" } },
    [
      _c(
        "el-form",
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.records,
                "header-cell-style": { background: "#F6F6F6" }
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "goodsItemValue", label: "", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return _vm._l(row.itemSellAttributes, function(
                        item,
                        index
                      ) {
                        return _c("span", { key: index }, [
                          _vm._v(_vm._s(item.itemValue) + " ")
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "goodsStock", label: "库存", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("el-input-number", {
                          attrs: { controls: false },
                          on: {
                            change: function($event) {
                              return _vm.handleChange(row)
                            }
                          },
                          model: {
                            value: row.goodsStock,
                            callback: function($$v) {
                              _vm.$set(row, "goodsStock", $$v)
                            },
                            expression: "row.goodsStock"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "captureCount",
                  label: "已占数量",
                  align: "center"
                }
              })
            ],
            1
          ),
          _c("el-pagination", {
            staticClass: "d2-fr d2-mr d2-mt",
            attrs: {
              small: "",
              total: _vm.total,
              "page-size": _vm.pageSize,
              layout: "prev, pager, next",
              "hide-on-single-page": ""
            },
            on: {
              "current-change": _vm.currentChange,
              "prev-click": _vm.currentChange,
              "next-click": _vm.currentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }