var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "steps-main" },
    [
      _vm._l(_vm.steps, function(l, i) {
        return [
          _c("div", { key: i, staticClass: "steps" }, [
            _c(
              "div",
              { staticClass: "icon", class: { "is-active": !l.stutas } },
              [_c("i", { class: l.stutas ? "el-icon-time" : "el-icon-check" })]
            ),
            _c("h5", [_vm._v(_vm._s(l.label))]),
            _c("span", [_vm._v(_vm._s(_vm.desc(i)))])
          ])
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }