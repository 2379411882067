import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5b39f95a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./index.vue?vue&type=style&index=1&id=5b39f95a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b39f95a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Users\\Admin\\WebstormProjects\\middle-east-pc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5b39f95a')) {
      api.createRecord('5b39f95a', component.options)
    } else {
      api.reload('5b39f95a', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=5b39f95a&scoped=true&", function () {
      api.rerender('5b39f95a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/d2-chat/index.vue"
export default component.exports