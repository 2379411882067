import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.iterator";
import _objectSpread from "D:/Users/Admin/WebstormProjects/middle-east-pc/node_modules/@babel/runtime/helpers/esm/objectSpread2";

/**
 * @description 系统默认页面
 */
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV); // 自动读取.page.vue结尾的文件，写进路由


var files = require.context('../views', true, /\.page\.vue$/);

var generators = files.keys().map(function (key) {
  return {
    path: files(key).default.path || files(key).default.name,
    meta: _objectSpread({}, files(key).default.meta),
    name: files(key).default.name,
    component: _import(key.replace('./', ''))
  };
});
/**
 * 在主框架之外显示
 */

var frameOut = [{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}, {
  path: '*',
  name: '404',
  component: _import('system/error/404')
}, //忘记密码
{
  path: '/forgetPwd',
  name: 'forgetPwd',
  meta: {
    auth: false,
    title: '忘记密码'
  },
  component: _import('user/forgetPwd')
}];
/**
 * 在主框架内显示
 */

var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [{
    path: 'index',
    name: 'index',
    meta: {
      auth: true,
      title: '用户列表'
    },
    component: _import('user/users')
  }, // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  }, // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  } // ...generators
  ]
}]; // 导出需要显示菜单的

export var frameInRoutes = frameIn; // 导出默认框架内显示的路由

export var routesInLayout = frameIn; // 导出默认框架外显示的路由

export var routesOutLayout = frameOut; // 导出默认框架动态的路由

export var generatorsRoutes = generators;