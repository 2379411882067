var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [_vm._v("上级分类：" + _vm._s(_vm.$route.query.name))])
          ]),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-style": { background: "#F6F6F6" }
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "typeNo", label: "编号", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { prop: "typeName", label: "分类名称", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { prop: "typeLevel", label: "级别", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { prop: "typeShow", label: "是否显示", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(" " + _vm._s(row.typeShow ? "是" : "否") + " ")
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "typeSort", label: "排序", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleNav("trade-add-edit", {
                                  goodsTypeId: row.goodsTypeId
                                })
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _c("span", { staticClass: "d2-pl-10" }),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.deleteStoreGoodsType(row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("d2-pagination", {
            attrs: { total: _vm.total },
            on: { pagination: _vm.pagination }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }