var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "collapse" },
    [
      _vm._l(_vm.collapseData, function(l, i) {
        return [
          _c(
            "div",
            {
              key: i,
              staticClass: "collapse-item",
              class: { "is-active": _vm.activeNames == i }
            },
            [
              _c("div", { staticClass: "collapse-title" }, [
                _c(
                  "div",
                  {
                    staticClass: "left",
                    on: {
                      click: function($event) {
                        return _vm.itemClick(l, i)
                      }
                    }
                  },
                  [
                    _c("i", {
                      class:
                        _vm.activeNames == i
                          ? "el-icon-arrow-up"
                          : "el-icon-arrow-down"
                    }),
                    _c("span", [_vm._v(_vm._s(l.deliveryFeeName))])
                  ]
                ),
                _c("div", { staticClass: "right" }, [
                  _c("div", { staticClass: "time" }, [
                    _vm._v("最后编辑时间：" + _vm._s(l.updateTime))
                  ]),
                  _c(
                    "div",
                    { staticClass: "btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.goRoute("addTransportModule", {
                                deliveryFeeId: l.deliveryFeeId
                              })
                            }
                          }
                        },
                        [_vm._v("修改 ")]
                      ),
                      _c("span", [_vm._v(" - ")]),
                      _c(
                        "el-popconfirm",
                        {
                          attrs: { title: "确定删除吗？" },
                          on: {
                            confirm: function($event) {
                              return _vm.clickDel({
                                deliveryFeeId: l.deliveryFeeId
                              })
                            }
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference"
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]),
              _c("transition", { key: i, attrs: { name: "fade-transverse" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeNames == i,
                        expression: "activeNames == i"
                      }
                    ],
                    staticClass: "collapse-body"
                  },
                  [
                    _c(
                      "el-descriptions",
                      {
                        staticClass: "d2-p-10",
                        attrs: {
                          column: 12,
                          direction: "vertical",
                          colon: false
                        }
                      },
                      [
                        _c("el-descriptions-item", {
                          attrs: { label: "默认", span: 2 }
                        }),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "首" + _vm.isMoney(l) } },
                          [_vm._v(_vm._s(l.baseCount.toFixed(2)))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "运费(元)" } },
                          [_vm._v(_vm._s(l.basePrice.toFixed(2)))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "续" + _vm.isMoney(l) } },
                          [_vm._v(_vm._s(l.incrementCount.toFixed(2)))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "续费(元)" } },
                          [_vm._v(_vm._s(l.incrementPrice.toFixed(2)))]
                        )
                      ],
                      1
                    ),
                    _vm._l(l.specialAreas, function(s, ii) {
                      return _c(
                        "el-descriptions",
                        {
                          key: ii,
                          staticClass: "d2-p-10",
                          attrs: {
                            column: 12,
                            direction: "vertical",
                            colon: false
                          }
                        },
                        [
                          _c("el-descriptions-item", {
                            attrs: {
                              label: _vm._f("provincesName")(s.provinces),
                              span: 2
                            }
                          }),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "首" + _vm.isMoney(s) } },
                            [_vm._v(_vm._s(s.baseCount.toFixed(2)))]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "运费(元)" } },
                            [_vm._v(_vm._s(s.basePrice.toFixed(2)))]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "续" + _vm.isMoney(s) } },
                            [_vm._v(_vm._s(s.incrementCount.toFixed(2)))]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "续费(元)" } },
                            [_vm._v(_vm._s(s.incrementPrice.toFixed(2)))]
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ])
            ],
            1
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }