var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%", height: "100%" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.propr,
              expression: "propr"
            }
          ],
          staticClass: "init-propr",
          attrs: { tabindex: "0" },
          on: { focusout: _vm.handleFocusOut }
        },
        [
          _c(
            "el-scrollbar",
            { staticStyle: { height: "250px" } },
            [
              _c(
                "el-card",
                {
                  attrs: { shadow: "never", "body-style": { padding: "20px" } }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    _vm._l(_vm.emojiQQ, function(item) {
                      return _c(
                        "el-col",
                        { key: item, staticClass: "d2-pb", attrs: { span: 4 } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "grid-content",
                              on: {
                                click: function($event) {
                                  return _vm.clickEmoji(item)
                                }
                              }
                            },
                            [_vm._v(_vm._s(item))]
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "lemon-imui",
        _vm._b(
          {
            ref: "IMUI",
            staticClass: "lemon-editor__input_focus",
            attrs: { user: this.user },
            on: {
              "pull-messages": _vm.handlePullMessages,
              send: _vm.handleSend,
              "message-click": _vm.messageClick
            },
            scopedSlots: _vm._u([
              {
                key: "message-title",
                fn: function(scope) {
                  return _c(
                    "div",
                    {
                      staticClass: "header_style",
                      attrs: { flex: "main:justify" }
                    },
                    [
                      _c("div", [_vm._v(_vm._s(scope.displayName))]),
                      _c("el-button", {
                        staticClass: "d2-p-0",
                        staticStyle: { color: "#fff" },
                        attrs: { type: "text", icon: "el-icon-arrow-down" },
                        on: {
                          click: function($event) {
                            return _vm.isImShowSet(false)
                          }
                        }
                      })
                    ],
                    1
                  )
                }
              }
            ])
          },
          "lemon-imui",
          _vm.initData,
          false
        )
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "视频播放",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("video", {
            attrs: {
              src: _vm.videoSrc,
              width: "100%",
              height: "300px",
              autoplay: "",
              controls: ""
            }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }