var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_c("d2-page-head")], 1),
      _c(
        "div",
        { attrs: { flex: "main:center" } },
        [
          _c(
            "el-form",
            {
              ref: "loginForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.formLogin,
                "label-width": "120px"
              }
            },
            [
              _c("h4", [_vm._v("验证身份")]),
              _c("p", [
                _vm._v(
                  "当前绑定手机号码是：" +
                    _vm._s(
                      _vm.oldPhone
                        ? _vm.oldPhone.replace(_vm.oldPhone.slice(3, 7), "****")
                        : ""
                    )
                )
              ]),
              _c(
                "el-form-item",
                { attrs: { prop: "oldVerifyCode", label: "当前手机验证码：" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.formLogin.oldVerifyCode,
                        callback: function($$v) {
                          _vm.$set(_vm.formLogin, "oldVerifyCode", $$v)
                        },
                        expression: "formLogin.oldVerifyCode"
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100px" },
                              attrs: {
                                type: "text",
                                disabled: _vm.getOldCodeDisabled
                              },
                              on: {
                                click: function($event) {
                                  return _vm.getCodePhone()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.getOldCodeText))]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c("h4", [_vm._v("设置新手机号码")]),
              _c(
                "el-form-item",
                { attrs: { prop: "accountPhone", label: "新手机号码：" } },
                [
                  _c("el-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.formLogin.accountPhone,
                      callback: function($$v) {
                        _vm.$set(_vm.formLogin, "accountPhone", $$v)
                      },
                      expression: "formLogin.accountPhone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "verifyCode", label: "新手机验证码：" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.formLogin.verifyCode,
                        callback: function($$v) {
                          _vm.$set(_vm.formLogin, "verifyCode", $$v)
                        },
                        expression: "formLogin.verifyCode"
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100px" },
                              attrs: {
                                type: "text",
                                disabled: _vm.getCodeDisabled
                              },
                              on: {
                                click: function($event) {
                                  return _vm.getNewCodePhone()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.getCodeText))]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "d2-pl d2-pr el-btn",
                      on: { click: _vm.submit }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }