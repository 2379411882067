var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c(
        "el-form",
        { staticStyle: { "margin-bottom": "15px" } },
        [
          _c(
            "base-form",
            {
              attrs: { form: _vm.form, initForm: _vm.initForm },
              on: {
                "update:form": function($event) {
                  _vm.form = $event
                },
                search: _vm.handleSearch
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品名称" },
                    model: {
                      value: _vm.form.goodsName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "goodsName", $$v)
                      },
                      expression: "form.goodsName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { slot: "extra" },
                  on: { click: _vm.toSave },
                  slot: "extra"
                },
                [_vm._v("添加新的秒杀活动")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "商品名称", prop: "goodsName", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { label: "商品原价", prop: "goodsPrice", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { label: "秒杀价格", prop: "seckillPrice", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { label: "截止日期", prop: "seckillEndTime" }
          }),
          _c("el-table-column", {
            attrs: { label: "排序", prop: "seckillSort" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.toUpdate(row)
                          }
                        }
                      },
                      [_vm._v(" 修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.del(row)
                          }
                        }
                      },
                      [_vm._v(" 删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }