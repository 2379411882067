import _objectSpread from "D:/Users/Admin/WebstormProjects/middle-east-pc/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _mergeJSXProps from "@vue/babel-helper-vue-jsx-merge-props";
export default {
  name: 'lemonMessageVideo',
  inheritAttrs: false,
  inject: ['IMUI'],
  render: function render() {
    var h = arguments[0];
    return h("lemon-message-basic", _mergeJSXProps([{}, {
      "props": _objectSpread({}, this.$attrs)
    }, {
      "scopedSlots": {
        content: function content(props) {
          //返回HTML结构
          return h("div", {
            "class": 'video'
          }, [h("img", {
            "style": {
              width: '100px',
              'object-fit': 'cover'
            },
            "attrs": {
              "src": props.content
            }
          })]);
        }
      }
    }]));
  }
};