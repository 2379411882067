var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      ref: "countPopover",
      attrs: {
        align: "center",
        placement: "right",
        trigger: "click",
        width: "1000px"
      }
    },
    [
      _c("div", { attrs: { flex: "main:right" } }, [
        _c("i", { staticClass: "el-icon el-icon-close" })
      ]),
      _c(
        "div",
        { attrs: { flex: "main:justify" } },
        [
          _c("label", [_vm._v("总库存：")]),
          _c("el-input", {
            staticStyle: { width: "100px" },
            attrs: { clearable: "" }
          })
        ],
        1
      ),
      _c("div", { attrs: { flex: "main:justify" } }, [
        _c("span"),
        _c("span", [_vm._v("库存")]),
        _c("span", [_vm._v("已占数量")])
      ]),
      _c(
        "div",
        { attrs: { flex: "main:justify" } },
        [
          _c("span", { staticClass: "d2-pr" }, [_vm._v("杠铃")]),
          _c("el-input", {
            staticStyle: { width: "100px" },
            attrs: { clearable: "" }
          }),
          _c("span", { staticClass: "d2-pl" }, [_vm._v("0")])
        ],
        1
      ),
      _c(
        "span",
        {
          directives: [
            {
              name: "popover",
              rawName: "v-popover:countPopover",
              arg: "countPopover"
            }
          ],
          attrs: { slot: "reference" },
          slot: "reference"
        },
        [_vm._v(_vm._s(_vm.scope.row[_vm.propName]))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }