var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _c("transition", { attrs: { name: "el-zoom-in-bottom" } }, [
        _vm.isImShow && _vm.isImLogin && _vm.isImFormId
          ? _c(
              "div",
              {
                staticClass: "make",
                staticStyle: { width: "500px", height: "600px" }
              },
              [_c("chat")],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }