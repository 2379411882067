var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_c("d2-page-head")], 1),
      _c("el-alert", {
        staticClass: "d2-mt-10 d2-mb-10",
        attrs: {
          title:
            "订单为未完成状态时，存在订单取消及退款的可能性，应结金额仅供参考。请以最终实际结算金额及流水为准",
          type: "warning",
          closable: false,
          "show-icon": ""
        }
      }),
      _c(
        "d2-search",
        {
          attrs: { toSearch: _vm.toSearch },
          on: {
            d2Search: _vm.handleSearch,
            reset: function($event) {
              return _vm.handleReset(1)
            }
          }
        },
        [
          _c(
            "el-button",
            {
              attrs: { slot: "bottom", type: "primary" },
              on: { click: _vm.download },
              slot: "bottom"
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c(
        "el-descriptions",
        { staticClass: "d2-mt d2-mb", attrs: { column: 3, title: "查询合计" } },
        [
          _c("el-descriptions-item", { attrs: { label: "货款" } }, [
            _vm._v(_vm._s(_vm.extraData.goodsAmount))
          ]),
          _c("el-descriptions-item", { attrs: { label: "笔数" } }, [
            _vm._v(_vm._s(_vm.extraData.orderCount))
          ])
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: {
            data: _vm.orderDetails,
            border: "",
            "header-cell-style": { background: "#F6F6F6" }
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "orderNo", label: "订单编号", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "orderStatus", label: "订单状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("ftValue2label")(
                            row.orderStatus,
                            _vm.dict.orderStatus
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "orderTime", label: "下单时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "deliveryTime", label: "出库时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "completeTime", label: "完成时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "goodsItemList",
              label: "商品名称",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("goodsItemList")(row.goodsItemList)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsCount", label: "商品数量", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsNo", label: "商品编号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("goodsNo")(row.goodsItemList)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "trainingPrice",
              label: "金额",
              align: "center",
              width: "200px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("p", [
                      _vm._v(" 货款 "),
                      _c("span", [_vm._v(_vm._s(row.goodsAmount))])
                    ]),
                    _c("p", [
                      _vm._v(" 运费 "),
                      _c("span", [_vm._v(_vm._s(row.deliveryAmount))])
                    ]),
                    _c("p", [
                      _vm._v(" 优惠 "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (row.vipDiscountAmount + row.couponAmount).toFixed(
                              2
                            )
                          )
                        )
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "paymentTime",
              label: "收支方向和结算状态",
              align: "center",
              width: "200px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("p", [
                      _vm._v(" 收入 "),
                      _c("span", [
                        _vm._v(
                          _vm._s(row.goodsAmountSettled ? "已结算" : "未结算")
                        )
                      ])
                    ]),
                    _c("p", [
                      _vm._v(" 收入 "),
                      _c("span", [
                        _vm._v(
                          _vm._s(row.goodsAmountSettled ? "已结算" : "未结算")
                        )
                      ])
                    ]),
                    _c("p", [
                      _vm._v(" 支出 "),
                      _c("span", [
                        _vm._v(
                          _vm._s(row.goodsAmountSettled ? "已结算" : "未结算")
                        )
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "checkedTime",
              label: "结算时间",
              align: "center",
              width: "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("p", [
                      _vm._v(" " + _vm._s(row.goodsAmountSettlementTime))
                    ]),
                    _c("p", [
                      _vm._v(" " + _vm._s(row.goodsAmountSettlementTime))
                    ]),
                    _c("p", [
                      _vm._v(" " + _vm._s(row.goodsAmountSettlementTime))
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("d2-pagination", {
        attrs: { total: _vm.total, limit: _vm.limit, page: _vm.page },
        on: {
          "update:total": function($event) {
            _vm.total = $event
          },
          "update:limit": function($event) {
            _vm.limit = $event
          },
          "update:page": function($event) {
            _vm.page = $event
          },
          pagination: _vm.pagination
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }