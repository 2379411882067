var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "商品名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入商品名称" },
                model: {
                  value: _vm.form.goodsName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsName", $$v)
                  },
                  expression: "form.goodsName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品分类" } },
            [
              _c("el-cascader", {
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  options: _vm.typeOptions,
                  props: {
                    expandTrigger: "hover",
                    value: "goodsTypeId",
                    label: "typeName"
                  }
                },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.form.goodsTypeId1,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsTypeId1", $$v)
                  },
                  expression: "form.goodsTypeId1"
                }
              }),
              _vm.typeOptions2.length > 0
                ? _c("el-cascader", {
                    staticStyle: { "margin-right": "10px" },
                    attrs: {
                      options: _vm.typeOptions2,
                      props: {
                        expandTrigger: "hover",
                        value: "goodsTypeId",
                        label: "typeName"
                      }
                    },
                    model: {
                      value: _vm.form.goodsTypeId2,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "goodsTypeId2", $$v)
                      },
                      expression: "form.goodsTypeId2"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入商品编号" },
                model: {
                  value: _vm.form.goodsNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsNo", $$v)
                  },
                  expression: "form.goodsNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "价格" } },
            [
              _c("range-input", {
                attrs: {
                  min: _vm.form.priceRange.start,
                  max: _vm.form.priceRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.priceRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.priceRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品货号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入商品货号" },
                model: {
                  value: _vm.form.goodsModel,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsModel", $$v)
                  },
                  expression: "form.goodsModel"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品库存" } },
            [
              _c("range-input", {
                attrs: {
                  min: _vm.form.stockRange.start,
                  max: _vm.form.stockRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.stockRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.stockRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下架时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.shelfTimeRange.start,
                  max: _vm.form.shelfTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.shelfTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.shelfTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品状态" } },
            [
              _c("base-select", {
                attrs: { options: _vm.dict.orderGoodsStatus2 },
                model: {
                  value: _vm.form.goodsStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsStatus", $$v)
                  },
                  expression: "form.goodsStatus"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "header",
        { staticClass: "d2-pt d2-pb", attrs: { flex: "main:justify" } },
        [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("addGoods")
                    }
                  }
                },
                [_vm._v("添加新商品")]
              ),
              _c("el-button", { on: { click: _vm.clickOnAll } }, [
                _vm._v("批量上架")
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "base-table",
        {
          ref: "baseTable",
          attrs: { request: _vm.request },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection" } }),
          _c("el-table-column", {
            attrs: { "min-width": "400" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "goods-card",
                      { attrs: { row: row } },
                      [
                        _c("el-button", {
                          attrs: { type: "primary", icon: "el-icon-edit" },
                          on: {
                            click: function($event) {
                              return _vm.openEditGoodsName(row)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsStock", label: "总库存", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          type: "primary",
                          underline: false,
                          target: "_blank"
                        },
                        on: {
                          click: function($event) {
                            return _vm.clickOpenLayer2(scope.row)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(scope.row.goodsStock) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsPrice", label: "单价（元）" }
          }),
          _c("el-table-column", {
            attrs: { prop: "soldCount", label: "30天销量" }
          }),
          _c("el-table-column", {
            attrs: { prop: "onShelfTime", label: "上架时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "lastUpdateTime", label: "修改时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsStatus", label: "商品状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", { staticStyle: { "margin-right": "10px" } }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("ftValue2label")(
                            row.goodsStatus,
                            _vm.dict.orderGoodsStatus
                          )
                        )
                      )
                    ]),
                    row.goodsStatus == 4
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.goRoute("violation", {
                                  goodsId: row.goodsId
                                })
                              }
                            }
                          },
                          [_vm._v(" 违规原因 ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.goRoute("editGoods", {
                              goodsId: row.goodsId
                            })
                          }
                        }
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.onShelf(row)
                          }
                        }
                      },
                      [_vm._v("上架")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.delShop(row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "d2-layer",
        { attrs: { layer: _vm.layer }, on: { confirm: _vm.confirm } },
        [
          _c("el-cascader-panel", {
            attrs: { props: _vm.props },
            on: { change: _vm.changeCascader }
          })
        ],
        1
      ),
      _c(
        "d2-layer",
        { attrs: { layer: _vm.layer2 }, on: { confirm: _vm.confirm2 } },
        [
          _c("comGoodStock", {
            ref: "comGoodStock",
            attrs: { form: _vm.layer2.form }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }