var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "活动名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入活动名称" },
                model: {
                  value: _vm.form.couponName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "couponName", $$v)
                  },
                  expression: "form.couponName"
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "extra" },
              on: { click: _vm.upVolume },
              slot: "extra"
            },
            [_vm._v("发布新优惠券")]
          )
        ],
        1
      ),
      _c("TableTitleBar", { attrs: { title: "查询统计" } }, [
        _c("span", { staticClass: "space" }, [
          _vm._v("已领取总量：" + _vm._s(_vm.extraData.receivedCount))
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v(
            "未领取总量：" +
              _vm._s(_vm.extraData.couponCount - _vm.extraData.receivedCount)
          )
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v("领券用户总数：" + _vm._s(_vm.extraData.receiveUserCount))
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v("用券成交总额：" + _vm._s(_vm.extraData.orderAmount))
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v("用券完单总单量：" + _vm._s(_vm.extraData.orderCount))
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v("用户用券优惠总金额：" + _vm._s(_vm.extraData.orderSaveAmount))
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v("已使用总量：" + _vm._s(_vm.extraData.usedCount))
        ]),
        _c("span", { staticClass: "space" }, [
          _vm._v("未使用总量：" + _vm._s(_vm.extraData.noUsedCount))
        ])
      ]),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "活动名称", prop: "couponName", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { label: "满减金额", prop: "fullAmount", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        "满" +
                          _vm._s(row.fullAmount) +
                          "减" +
                          _vm._s(row.saveAmount)
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "失效日期", prop: "expireTime" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(row.expireTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "截止日期", prop: "receiveEndTime" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(row.receiveEndTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "发放日期", prop: "provideTime" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.couponStatus !== 1
                      ? _c("span", [
                          _vm._v(_vm._s(_vm._f("formatDate")(row.provideTime)))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "限量份数", prop: "couponCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "已领取总量", prop: "receivedCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "活动状态", prop: "couponStatus" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          ["", "未发放", "发放中", "已发放"][row.couponStatus]
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "使用说明", prop: "couponInstruction" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          disabled: row.couponStatus == 1
                        },
                        on: {
                          click: function($event) {
                            return _vm.see(row)
                          }
                        }
                      },
                      [_vm._v(" 查看")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          disabled: row.couponStatus !== 1
                        },
                        on: {
                          click: function($event) {
                            return _vm.save(row)
                          }
                        }
                      },
                      [_vm._v(" 发放")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          disabled: row.couponStatus == 2
                        },
                        on: {
                          click: function($event) {
                            return _vm.del(row)
                          }
                        }
                      },
                      [_vm._v(" 删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "活动详情",
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { ref: "chart1", staticStyle: { height: "400px" } }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }