var registerPlatformTypes = [{
  label: '全部',
  value: ''
}, {
  label: 'APP',
  value: 1
}, {
  label: '小程序',
  value: 2
}, {
  label: 'Web',
  value: 3
}];
export { registerPlatformTypes };