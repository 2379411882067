var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c("d2-page-head", {
            attrs: { title: "添加模板-" + (_vm.isRadio ? "按件数" : "按重量") }
          })
        ],
        1
      ),
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                model: _vm.formData,
                rules: _vm.isRules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "400px" },
                  attrs: { label: "模板名称：", prop: "deliveryFeeName" }
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.formData.deliveryFeeName,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "deliveryFeeName", $$v)
                      },
                      expression: "formData.deliveryFeeName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否包邮：", prop: "freed" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: false },
                      model: {
                        value: _vm.formData.freed,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "freed", $$v)
                        },
                        expression: "formData.freed"
                      }
                    },
                    [_vm._v("买家承担运费")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: true },
                      model: {
                        value: _vm.formData.freed,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "freed", $$v)
                        },
                        expression: "formData.freed"
                      }
                    },
                    [_vm._v("卖家承担运费")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "province" } },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.formData.specialAreas,
                        border: "",
                        stripe: ""
                      }
                    },
                    [
                      _c(
                        "el-table-column",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "tab-head" },
                                    [
                                      _c("b", [_vm._v("默认运费")]),
                                      _c("el-input-number", {
                                        attrs: { controls: false },
                                        on: { change: _vm.isNumber },
                                        model: {
                                          value: _vm.formData.baseCount,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "baseCount",
                                              $$v
                                            )
                                          },
                                          expression: "formData.baseCount"
                                        }
                                      }),
                                      _c("b", [
                                        _vm._v(_vm._s(_vm.isMoney) + "内，")
                                      ]),
                                      _c("el-input-number", {
                                        attrs: { controls: false },
                                        on: { change: _vm.isNumber },
                                        model: {
                                          value: _vm.formData.basePrice,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "basePrice",
                                              $$v
                                            )
                                          },
                                          expression: "formData.basePrice"
                                        }
                                      }),
                                      _c("b", [_vm._v("元，每增加")]),
                                      _c("el-input-number", {
                                        attrs: { controls: false },
                                        on: { change: _vm.isNumber },
                                        model: {
                                          value: _vm.formData.incrementCount,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "incrementCount",
                                              $$v
                                            )
                                          },
                                          expression: "formData.incrementCount"
                                        }
                                      }),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.isMoney) + "，增加运费"
                                        )
                                      ]),
                                      _c("el-input-number", {
                                        attrs: { controls: false },
                                        on: { change: _vm.isNumber },
                                        model: {
                                          value: _vm.formData.incrementPrice,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "incrementPrice",
                                              $$v
                                            )
                                          },
                                          expression: "formData.incrementPrice"
                                        }
                                      }),
                                      _c("b", [_vm._v("元")])
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "运送到", width: "300" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  var $index = ref.$index
                                  return [
                                    _c("checkCity", {
                                      ref: "checkCity",
                                      attrs: { layer: _vm.layer },
                                      on: {
                                        "update:layer": function($event) {
                                          _vm.layer = $event
                                        },
                                        confirm: _vm.confirmCheckCity
                                      }
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "flex-jus" },
                                      [
                                        !row.provinces.length
                                          ? _c("span", [_vm._v("未添加地区")])
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("provincesName")(
                                                    row.provinces
                                                  )
                                                )
                                              )
                                            ]),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.clickLayer(
                                                  row,
                                                  $index
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("编辑")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "baseCount",
                              label: _vm.isRadio ? "首件(个)" : "首重(kg)"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      attrs: { controls: false },
                                      on: { change: _vm.isNumber },
                                      model: {
                                        value: row.baseCount,
                                        callback: function($$v) {
                                          _vm.$set(row, "baseCount", $$v)
                                        },
                                        expression: "row.baseCount"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "basePrice", label: "首费(元)" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      attrs: { controls: false },
                                      on: { change: _vm.isNumber },
                                      model: {
                                        value: row.basePrice,
                                        callback: function($$v) {
                                          _vm.$set(row, "basePrice", $$v)
                                        },
                                        expression: "row.basePrice"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "incrementCount",
                              label: _vm.isRadio ? "续件(个)" : "续重(kg)"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      attrs: { controls: false },
                                      on: { change: _vm.isNumber },
                                      model: {
                                        value: row.incrementCount,
                                        callback: function($$v) {
                                          _vm.$set(row, "incrementCount", $$v)
                                        },
                                        expression: "row.incrementCount"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "incrementPrice",
                              label: "续费(元)"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      attrs: { controls: false },
                                      on: { change: _vm.isNumber },
                                      model: {
                                        value: row.incrementPrice,
                                        callback: function($$v) {
                                          _vm.$set(row, "incrementPrice", $$v)
                                        },
                                        expression: "row.incrementPrice"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var $index = ref.$index
                                  return [
                                    _c(
                                      "el-popconfirm",
                                      {
                                        attrs: { title: "确定删除吗？" },
                                        on: {
                                          confirm: function($event) {
                                            return _vm.clickDel($index)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              slot: "reference",
                                              type: "text"
                                            },
                                            slot: "reference"
                                          },
                                          [_vm._v("删除")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "tabBtn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-plus" },
                          on: { click: _vm.clickAdd }
                        },
                        [_vm._v("为指定地区城市设置运费")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }