var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c(
        "el-form",
        { ref: "formData", attrs: { model: _vm.formData, rules: _vm.rules } },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
            [
              _c(
                "div",
                {
                  staticClass: "title",
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [_c("span", [_vm._v("一、基本信息")])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品分类：", "label-width": "120px" } },
                [
                  _c("el-cascader", {
                    key: _vm.cascader_key,
                    ref: "cascader",
                    staticStyle: { width: "300px" },
                    attrs: {
                      separator: " > ",
                      props: _vm.cascaderProps,
                      disabled: ""
                    },
                    model: {
                      value: _vm.cascader_value,
                      callback: function($$v) {
                        _vm.cascader_value = $$v
                      },
                      expression: "cascader_value"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "商品标题：",
                    prop: "goodsName",
                    "label-width": "120px"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请输入内容" },
                    model: {
                      value: _vm.formData.goodsName,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "goodsName", $$v)
                      },
                      expression: "formData.goodsName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "商品标语：",
                    prop: "goodsSlogan",
                    "label-width": "120px"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { maxlength: 80 },
                    model: {
                      value: _vm.formData.goodsSlogan,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "goodsSlogan", $$v)
                      },
                      expression: "formData.goodsSlogan"
                    }
                  }),
                  _c("br"),
                  _c(
                    "span",
                    { staticStyle: { "font-size": "14px", color: "#606266" } },
                    [
                      _vm._v(
                        "“商品标语”文字最多输入80个字符（汉字、英文字母、标点、数字均算一个字符）"
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "货号：",
                    prop: "goodsModel",
                    "label-width": "120px"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.formData.goodsModel,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "goodsModel", $$v)
                      },
                      expression: "formData.goodsModel"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-card",
            { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
            [
              _c(
                "div",
                {
                  staticClass: "title",
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [_c("span", [_vm._v("二、销售属性")])]
              ),
              _vm._l(_vm.attrList, function(l, i) {
                return _c("el-form-item", { key: i }, [
                  _c("div", [_vm._v(_vm._s(l.attributeName))]),
                  !!l
                    ? _c(
                        "div",
                        { staticClass: "check-box" },
                        _vm._l(l.items, function(k) {
                          return _c(
                            "el-checkbox",
                            {
                              key: k.sellAttributeItemId,
                              attrs: { checked: k.selected },
                              on: {
                                change: function(e) {
                                  return _vm.changeCheckbox(e, k)
                                }
                              },
                              model: {
                                value: k.selected,
                                callback: function($$v) {
                                  _vm.$set(k, "selected", $$v)
                                },
                                expression: "k.selected"
                              }
                            },
                            [_vm._v(" " + _vm._s(k.itemValue) + " ")]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { attrs: { flex: "main:justify" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-plus" },
                          on: {
                            click: function() {
                              return _vm.addSellAttributes(l)
                            }
                          }
                        },
                        [_vm._v("添加规格值")]
                      )
                    ],
                    1
                  )
                ])
              }),
              _c("editTable", {
                attrs: {
                  tableData: _vm.formData.goodsItems,
                  tableProp: _vm.tableProp
                },
                on: {
                  "update:tableData": function($event) {
                    return _vm.$set(_vm.formData, "goodsItems", $event)
                  },
                  "update:table-data": function($event) {
                    return _vm.$set(_vm.formData, "goodsItems", $event)
                  },
                  tabDelete: _vm.tabDelete
                }
              })
            ],
            2
          ),
          _c(
            "el-card",
            { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
            [
              _c(
                "div",
                {
                  staticClass: "title",
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [_c("span", [_vm._v("三、商品图片")])]
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "goodsImages" } },
                [
                  _c(
                    "d2-upload",
                    _vm._b(
                      {
                        ref: "d2Upload",
                        staticClass: "d2-p-10",
                        staticStyle: { "background-color": "#f5f7f9" },
                        on: { input: _vm.imgEmits },
                        model: {
                          value: _vm.formData.goodsImages,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goodsImages", $$v)
                          },
                          expression: "formData.goodsImages"
                        }
                      },
                      "d2-upload",
                      _vm.imgAttrs,
                      false
                    )
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "d2-m-10",
                      attrs: { type: "primary" },
                      on: { click: _vm.d2Upload }
                    },
                    [_vm._v("上传图片")]
                  ),
                  _c("span", { staticClass: "d2-pl" }, [
                    _vm._v(
                      "最多可以上传10张图片，建议尺寸800*800 您可以拖拽图片调整顺序"
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "goodsPrimaryVideo" } },
                [
                  _c(
                    "el-row",
                    { attrs: { type: "flex", align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("upload-video", {
                            staticClass: "d2-p-10",
                            attrs: {
                              src: _vm.formData.goodsPrimaryVideo,
                              value: _vm.formData.goodsPrimaryVideo
                            },
                            on: {
                              "update:src": function($event) {
                                return _vm.$set(
                                  _vm.formData,
                                  "goodsPrimaryVideo",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {},
                        [
                          _c(
                            "el-row",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                                "align-items": "start"
                              },
                              attrs: { type: "flex" }
                            },
                            [
                              _vm.formData.goodsPrimaryVideoCover
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: { click: _vm.delVideoCover }
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-upload",
                                _vm._b(
                                  {
                                    staticStyle: { display: "inline-block" },
                                    attrs: {
                                      "on-success": function(res) {
                                        return _vm.handleSuccess(
                                          res,
                                          "goodsPrimaryVideoCover"
                                        )
                                      },
                                      "before-upload": _vm.beforeUpload
                                    }
                                  },
                                  "el-upload",
                                  _vm.uploadData,
                                  false
                                ),
                                [
                                  _vm.formData.goodsPrimaryVideoCover
                                    ? _c("el-image", {
                                        staticClass: "avatar",
                                        staticStyle: {
                                          width: "100px",
                                          height: "120px"
                                        },
                                        attrs: {
                                          src:
                                            _vm.formData.goodsPrimaryVideoCover,
                                          fit: "cover"
                                        }
                                      })
                                    : _c(
                                        "el-button",
                                        { attrs: { type: "primary" } },
                                        [_vm._v("上传视频封面")]
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-card",
            { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
            [
              _c(
                "div",
                {
                  staticClass: "title",
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [_c("span", [_vm._v("四、商品详情")])]
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "手机版商品详情：", prop: "goodsContentApp" }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "40px" } },
                    [
                      _c("d2-quill", {
                        attrs: { content: _vm.formData.goodsContentApp },
                        on: {
                          "update:content": function($event) {
                            return _vm.$set(
                              _vm.formData,
                              "goodsContentApp",
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.formData.goodsContentApp,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goodsContentApp", $$v)
                          },
                          expression: "formData.goodsContentApp"
                        }
                      }),
                      _c(
                        "div",
                        { staticStyle: { flex: "1", "font-size": "12px" } },
                        [
                          _vm._v(
                            " 1、满足规范标准且含有必备模块； 2、每张宽度建议640像素，所有图片宽度一致； 3、每张高度小于等于960像素； 4、每张图片容量应小于等于3000KB； 5、图片格式为：JPG\\GIF\\PNG ； 6、图片总张数建议大于5张，但不超过30张； 7、为了更好的视觉呈现，图片上的文字字号建议不小于20号； "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-card",
            { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
            [
              _c(
                "div",
                {
                  staticClass: "title",
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [_c("span", [_vm._v("五、售后物流")])]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "运费：",
                    prop: "deliveryFeeId",
                    "label-width": "120px"
                  }
                },
                [
                  _c("div", { attrs: { flex: "main" } }, [
                    _c(
                      "div",
                      { attrs: { flex: "main" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.formData.deliveryFeeId,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "deliveryFeeId", $$v)
                              },
                              expression: "formData.deliveryFeeId"
                            }
                          },
                          _vm._l(_vm.deliveryOptions, function(item) {
                            return _c("el-option", {
                              key: item.deliveryFeeId,
                              attrs: {
                                label: item.deliveryFeeName,
                                value: item.deliveryFeeId
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "d2-ml" })
                  ]),
                  _vm.deliveryTip
                    ? _c("div", { staticClass: "card-tip" }, [
                        _c("div", { staticClass: "tit" }, [
                          _vm._v(_vm._s(_vm.deliveryName))
                        ]),
                        _c("div", { staticClass: "centent" }, [
                          _vm._v(_vm._s(_vm.deliveryTip))
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "包装清单：",
                    "label-width": "120px",
                    prop: "packingList"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "500px" },
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.formData.packingList,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "packingList", $$v)
                      },
                      expression: "formData.packingList"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "售后服务：",
                    "label-width": "120px",
                    prop: "afterSaleService"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "500px" },
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.formData.afterSaleService,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "afterSaleService", $$v)
                      },
                      expression: "formData.afterSaleService"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          false
            ? _c(
                "el-card",
                {
                  attrs: { shadow: "never", "body-style": { padding: "20px" } }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("六、功能设置")])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "定时上架：",
                        prop: "autoOnShelf",
                        "label-width": "120px"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "d2-mr",
                          model: {
                            value: _vm.formData.autoOnShelf,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "autoOnShelf", $$v)
                            },
                            expression: "formData.autoOnShelf"
                          }
                        },
                        [_vm._v("设定")]
                      ),
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": "12:00:00",
                          format: "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: _vm.formData.autoOnShelfTime,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "autoOnShelfTime", $$v)
                          },
                          expression: "formData.autoOnShelfTime"
                        }
                      }),
                      _c("span", { staticClass: "d2-ml" }, [
                        _vm._v("系统会在该时间自动执行上架操作")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "定时下架：",
                        prop: "autoOffShelf",
                        "label-width": "120px"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "d2-mr",
                          model: {
                            value: _vm.formData.autoOffShelf,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "autoOffShelf", $$v)
                            },
                            expression: "formData.autoOffShelf"
                          }
                        },
                        [_vm._v("设定")]
                      ),
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": "12:00:00",
                          format: "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: _vm.formData.autoOffShelfTime,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "autoOffShelfTime", $$v)
                          },
                          expression: "formData.autoOffShelfTime"
                        }
                      }),
                      _c("span", { staticClass: "d2-ml" }, [
                        _vm._v("系统会在该时间自动执行下架操作")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { attrs: { flex: "main:center" } },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function($event) {
                    return _vm.onSubmit({ goodsStatus: 2 })
                  }
                }
              },
              [_vm._v("保存至待售")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.onSubmit({ goodsStatus: 1 })
                  }
                }
              },
              [_vm._v("开始销售")]
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }