var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户", prop: "keyword" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { placeholder: "用户ID、用户昵称、手机号码" },
                model: {
                  value: _vm.form.keyword,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "keyword", $$v)
                  },
                  expression: "form.keyword"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "注册日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.registerRange.start,
                  max: _vm.form.registerRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.registerRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.registerRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品成交额" } },
            [
              _c("range-input", {
                attrs: {
                  min: _vm.form.goodsAmountRange.start,
                  max: _vm.form.goodsAmountRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.goodsAmountRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.goodsAmountRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品成交量" } },
            [
              _c("range-input", {
                attrs: {
                  min: _vm.form.goodsCountRange.start,
                  max: _vm.form.goodsCountRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.goodsCountRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.goodsCountRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { slot: "extra", label: "" }, slot: "extra" },
            [
              _c(
                "el-button",
                { attrs: { type: "default" }, on: { click: _vm.download } },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", { attrs: { label: "用户ID", prop: "userNo" } }),
          _c("el-table-column", {
            attrs: { label: "用户昵称", prop: "nickname" }
          }),
          _c("el-table-column", {
            attrs: { label: "手机号码", prop: "phoneNumber" }
          }),
          _c("el-table-column", {
            attrs: { label: "商品成交总额", prop: "goodsOrderAmount" }
          }),
          _c("el-table-column", {
            attrs: { label: "商品成交单量", prop: "goodsOrderCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "注册时间", prop: "createTime", width: "200" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }