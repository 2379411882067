function getBooleanOptions() {
  var options = [{
    label: '全部',
    value: ''
  }, {
    label: '是',
    value: 1
  }, {
    label: '否',
    value: 2
  }];
  return options;
}

export { getBooleanOptions };