var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d2-pb" },
    [
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "订单编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入订单编号" },
                model: {
                  value: _vm.form.orderNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "orderNo", $$v)
                  },
                  expression: "form.orderNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入商品名称" },
                model: {
                  value: _vm.form.goodsName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsName", $$v)
                  },
                  expression: "form.goodsName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下单时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.orderTimeRange.start,
                  max: _vm.form.orderTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.orderTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.orderTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户电话" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入客户电话" },
                model: {
                  value: _vm.form.phoneNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "phoneNumber", $$v)
                  },
                  expression: "form.phoneNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下单账号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入下单账号" },
                model: {
                  value: _vm.form.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname"
                }
              })
            ],
            1
          ),
          _vm.isShowAuto("store-order-export")
            ? _c(
                "el-button",
                {
                  attrs: { slot: "extra", type: "default" },
                  on: { click: _vm.download },
                  slot: "extra"
                },
                [_vm._v("导出订单")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "base-table",
        {
          ref: "baseTable",
          attrs: { request: _vm.request },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "orderNo", label: "订单编号", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "orderTime", label: "下单时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "payTime", label: "付款时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "nickname", label: "下单账号", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "storeRemark", label: "商家备注", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "orderStatus", label: "订单状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("ftValue2label")(
                            row.orderStatus,
                            _vm.dict.orderStatus
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.goRoute("orderDetail", {
                              orderId: row.orderId
                            })
                          }
                        }
                      },
                      [_vm._v("订单详情")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "d2-layer",
        { attrs: { layer: _vm.layer }, on: { confirm: _vm.confirm } },
        [
          _vm.active.type == "updateDeliveryAmount"
            ? _c(
                "div",
                [
                  _c(
                    "el-descriptions",
                    { attrs: { direction: "vertical", column: 2, border: "" } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "原运费(元)" } },
                        [_vm._v(_vm._s(_vm.active.deliveryAmount))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "运费(元)" } },
                        [
                          _c("el-input-number", {
                            attrs: { controls: false },
                            model: {
                              value: _vm.layer.form.deliveryAmount,
                              callback: function($$v) {
                                _vm.$set(_vm.layer.form, "deliveryAmount", $$v)
                              },
                              expression: "layer.form.deliveryAmount"
                            }
                          }),
                          _c("span", [_vm._v("直接输入优惠后的金额")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }