var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d2-pb" }, [
    _c(
      "div",
      { staticClass: "d2-mt" },
      [
        _vm._m(0),
        _c(
          "el-descriptions",
          { attrs: { direction: "vertical", column: 5, border: "" } },
          [
            _c("el-descriptions-item", { attrs: { label: "买家" } }, [
              _vm._v(_vm._s(_vm.orderDetail.nickname))
            ]),
            _c("el-descriptions-item", { attrs: { label: "收货人" } }, [
              _vm._v(_vm._s(_vm.orderDetail.consigneeInfo.consigneeName))
            ]),
            _c("el-descriptions-item", { attrs: { label: "收货地址" } }, [
              _vm._v(
                _vm._s(
                  _vm.orderDetail.consigneeInfo.consigneeProvince +
                    _vm.orderDetail.consigneeInfo.consigneeCity +
                    _vm.orderDetail.consigneeInfo.consigneeDetails
                )
              )
            ]),
            _c("el-descriptions-item", { attrs: { label: "手机号码" } }, [
              _vm._v(_vm._s(_vm.orderDetail.consigneeInfo.consigneePhone))
            ]),
            _c("el-descriptions-item", { attrs: { label: "买家留言" } }, [
              _vm._v(_vm._s(_vm.orderDetail.buyerRemark))
            ])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d2-mt" },
      [
        _c(
          "el-table",
          {
            attrs: {
              data: _vm.orderDetail.goodsItemList,
              border: "",
              "header-cell-style": { background: "#F6F6F6" }
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "goodsNo",
                label: "商品编号",
                width: "180",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "goodsName",
                label: "商品名称",
                width: "180",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "goodsPrice", label: "商品价格", align: "center" }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "itemSellAttributes",
                label: "属性",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _vm._v(" " + _vm._s(_vm.itemSellAttributesFn(row)) + " ")
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { prop: "goodsCount", label: "商品数量", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "subtotalAmount", label: "小计", align: "center" }
            })
          ],
          1
        ),
        _c("div", { staticClass: "table-footer" }, [
          _c("div", [
            _c("label", [_vm._v("商品总额：")]),
            _c("span", [_vm._v("￥ " + _vm._s(_vm.orderDetail.goodsAmount))])
          ]),
          _c("div", [
            _c("label", [_vm._v("+运费：")]),
            _c("span", [_vm._v("￥ " + _vm._s(_vm.orderDetail.deliveryAmount))])
          ]),
          _c("div", [
            _c("label", [_vm._v("-优惠券减免金额：")]),
            _c("span", [
              _vm._v(
                "￥ " + _vm._s(_vm._f("toFixed2")(_vm.orderDetail.couponAmount))
              )
            ])
          ]),
          _c("div", [
            _c("label", [_vm._v("-Vip减免金额：")]),
            _c("span", [
              _vm._v(
                "￥ " +
                  _vm._s(_vm._f("toFixed2")(_vm.orderDetail.vipDiscountAmount))
              )
            ])
          ]),
          _c("div", [
            _c("label", [_vm._v("应付总额：")]),
            _c("span", [_vm._v("￥ " + _vm._s(_vm.orderDetail.finalAmount))])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("i", {
        staticClass: "el-icon-user-solid",
        staticStyle: { "margin-right": "8px" }
      }),
      _vm._v("收货人信息")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }