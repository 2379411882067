var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "d2-mr",
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.changeProvince },
                  model: {
                    value: _vm.address[0],
                    callback: function($$v) {
                      _vm.$set(_vm.address, 0, $$v)
                    },
                    expression: "address[0]"
                  }
                },
                _vm._l(_vm.provinceData, function(item) {
                  return _c("el-option", {
                    key: item.goodsTypeId,
                    attrs: { label: item.typeName, value: item.goodsTypeId }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.cityData.length > 0
            ? _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _vm.level > 1
                    ? _c(
                        "el-select",
                        {
                          staticClass: "d2-mr",
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.changeCity },
                          model: {
                            value: _vm.address[1],
                            callback: function($$v) {
                              _vm.$set(_vm.address, 1, $$v)
                            },
                            expression: "address[1]"
                          }
                        },
                        _vm._l(_vm.cityData, function(item) {
                          return _c("el-option", {
                            key: item.goodsTypeId,
                            attrs: {
                              label: item.typeName,
                              value: item.goodsTypeId
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.areaData.length > 0
            ? _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _vm.level > 2
                    ? _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.changeArea },
                          model: {
                            value: _vm.address[2],
                            callback: function($$v) {
                              _vm.$set(_vm.address, 2, $$v)
                            },
                            expression: "address[2]"
                          }
                        },
                        _vm._l(_vm.areaData, function(item) {
                          return _c("el-option", {
                            key: item.goodsTypeId,
                            attrs: {
                              label: item.typeName,
                              value: item.goodsTypeId
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }