var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    [
      _vm._l(_vm.attrList, function(l, i) {
        return _c("el-form-item", { key: i }, [
          _c("div", [_vm._v(_vm._s(l.attributeName))]),
          !!l
            ? _c(
                "div",
                { staticClass: "check-box" },
                _vm._l(l.items, function(k) {
                  return _c(
                    "el-checkbox",
                    {
                      key: k.sellAttributeItemId,
                      attrs: { checked: k.selected },
                      on: {
                        change: function(e) {
                          return _vm.changeCheckbox(e, k)
                        }
                      },
                      model: {
                        value: k.selected,
                        callback: function($$v) {
                          _vm.$set(k, "selected", $$v)
                        },
                        expression: "k.selected"
                      }
                    },
                    [_vm._v(" " + _vm._s(k.itemValue) + " ")]
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "div",
            { attrs: { flex: "main:justify" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text", icon: "el-icon-plus" },
                  on: {
                    click: function() {
                      return _vm.addSellAttributes(l)
                    }
                  }
                },
                [_vm._v("添加规格值")]
              )
            ],
            1
          )
        ])
      }),
      _c("editTable", {
        staticStyle: { width: "1000px" },
        attrs: { tableData: _vm.formData.goodsItems, tableProp: _vm.tableProp },
        on: { tabDelete: _vm.tabDelete }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }