export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    /**
     * @description 角色列表
     */
    positions: function positions() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/account/positions',
        data: data
      });
    },

    /**
     * @description 保存角色
     */
    savePosition: function savePosition() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/account/savePosition',
        data: data
      });
    },

    /**
     * @description 保存角色
     */
    deletePosition: function deletePosition() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/account/deletePosition',
        data: data
      });
    },

    /**
     * @description 全部权限
     */
    authorities: function authorities() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/account/authorities',
        data: data
      });
    },

    /**
     * @description 为角色设置权限
     */
    saveAuthorities: function saveAuthorities() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/account/saveAuthorities',
        data: data
      });
    },

    /**
     * @description 角色详情
     */
    positionDetails: function positionDetails() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/account/positionDetails',
        data: data
      });
    },

    /**
     * @description 部门列表
     */
    departments: function departments() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/account/departments',
        data: data
      });
    },

    /**
     * @description 删除部门
     */
    deleteDepartment: function deleteDepartment() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/account/deleteDepartment',
        data: data
      });
    },

    /**
     * @description 保存部门
     */
    saveDepartment: function saveDepartment() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/account/saveDepartment',
        data: data
      });
    },

    /**
     * @description 员工列表
     */
    staffList: function staffList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/account/staffList',
        data: data
      });
    },

    /**
     * @description 添加员工
     */
    saveStaff: function saveStaff() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/account/saveStaff',
        data: data
      });
    },

    /**
     * @description 冻结/启用 账号
     */
    lock: function lock() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/account/lock',
        data: data
      });
    },

    /**
     * @description 员工账号详情
     */
    staffDetails: function staffDetails() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/account/staffDetails',
        data: data
      });
    },

    /**
    * @description 删除员工
    */
    deleteStaff: function deleteStaff() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/account/deleteStaff',
        data: data
      });
    }
  };
});