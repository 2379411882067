var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderNum" },
    [
      _c(
        "div",
        { staticClass: "number" },
        [
          _c("label", [_vm._v("订单号：")]),
          _c("span", [_vm._v(_vm._s(_vm.orderDetail.orderNo))]),
          _c(
            "el-button",
            { attrs: { type: "text" }, on: { click: _vm.copyThat } },
            [_vm._v("复制")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "order-stutas" }, [
        _c("label", [_vm._v("订单状态：")]),
        _c("span", [
          _vm._v(
            _vm._s(
              _vm._f("ftValue2label")(
                _vm.orderDetail.orderStatus,
                _vm.dict.orderStatus
              )
            )
          )
        ])
      ]),
      _vm.isShow("store-order-remark")
        ? _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.layer.show = true
                }
              }
            },
            [
              _vm._v(
                _vm._s(_vm.orderDetail.storeRemark ? "修改备注" : "添加备注")
              )
            ]
          )
        : _vm._e(),
      _c(
        "d2-layer",
        { attrs: { layer: _vm.layer }, on: { confirm: _vm.confirm } },
        [
          _c("el-input", {
            attrs: { type: "textarea", resize: "none", rows: 5 },
            model: {
              value: _vm.layer.form.storeRemark,
              callback: function($$v) {
                _vm.$set(_vm.layer.form, "storeRemark", $$v)
              },
              expression: "layer.form.storeRemark"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }