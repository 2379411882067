//
//
//
//
//
//
export default {
  name: 'd2-select',
  props: {
    item: {
      type: Object
    }
  }
};