import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/Users/Admin/WebstormProjects/middle-east-pc/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _typeof from "D:/Users/Admin/WebstormProjects/middle-east-pc/node_modules/@babel/runtime/helpers/esm/typeof";
import utilRules from '@/libs/util.rules';

function ruleFor(name, config) {
  var obj = {
    required: true,
    message: "\u8BF7\u8F93\u5165".concat(name || ''),
    trigger: 'blur'
  };
  var arr = config ? [obj, config] : [obj];
  return arr;
}

function ForList(keyList) {
  var rule = {};
  var config = null;
  keyList.forEach(function (v) {
    if (v.validator) {
      config = {
        validator: utilRules[v.validator],
        trigger: 'blur'
      };
      rule[v.key] = ruleFor(v.name, config);
    } else {
      rule[v.key] = ruleFor(v.name);
    }
  });
  return rule;
}

function setRule(list) {
  var arr = list.map(function (v) {
    if (typeof v == 'string') {
      v = {
        key: v
      };
    } else if (_typeof(v) == 'object') {
      v = _objectSpread({}, v);
    }

    return v;
  });
  return ForList(arr);
}

export default setRule;