var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v(" 选择分类")])
          ])
        ]),
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("div", { staticClass: "panel-box" }, [
              _c(
                "div",
                { staticClass: "panel-item" },
                [_c("selectComp", { on: { change: _vm.selectCompChange } })],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: " d2-mb", staticStyle: { "margin-top": "40px" } },
              [
                _c("span", [
                  _vm._v("您当前选择的商品类别是：" + _vm._s(_vm.value))
                ])
              ]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.goRoute("newAddTrade")
                  }
                }
              },
              [_vm._v("下一步，填写商品信息")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }