// 数字
var numberReg = /^\d+$|^\d+[.]?\d+$/; // 中文

var cnReg = /^[\u4e00-\u9fa5]+$/; // 邮箱

var emailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/; // 手机号

var phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/; // 身份证号

export default {
  // 数字验证
  validateNumber: function validateNumber(rule, value, callback) {
    if (!numberReg.test(value)) {
      callback(new Error('请输入数字'));
    } else {
      callback();
    }
  },
  // 中文验证
  validateCn: function validateCn(rule, value, callback) {
    if (!cnReg.test(value)) {
      callback(new Error('请输入中文'));
    } else {
      callback();
    }
  },
  // 邮箱验证
  validateEmail: function validateEmail(rule, value, callback) {
    if (!emailReg.test(value)) {
      callback(new Error('请输入正确的邮箱'));
    } else {
      callback();
    }
  },
  // 电话验证
  validatePhone: function validatePhone(rule, value, callback) {
    if (!phoneReg.test(value)) {
      callback(new Error('请输入正确的手机号码'));
    } else {
      callback();
    }
  } // 身份证验证
  //   validatePhone: function(rule, value, callback) {
  //     if (!idCard.test(value)) {
  //       callback(new Error('请输入正确的身份证号码'))
  //     } else {
  //       callback()
  //     }
  //   }

};