//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'poprverComp',
  props: {
    scope: Object,
    propName: String
  }
};