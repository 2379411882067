var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.imgList != ""
        ? _c("div", { staticClass: "uploadIcon" }, [
            _c("video", { attrs: { src: _vm.imgList, controls: "controls" } }, [
              _vm._v("您的浏览器不支持视频播放")
            ]),
            _c(
              "div",
              { staticClass: "btn", attrs: { flex: "main" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.clickDel($event)
                      }
                    }
                  },
                  [_vm._v("删除视频")]
                )
              ],
              1
            )
          ])
        : _c(
            "el-upload",
            _vm._b(
              {
                ref: "uploadRef",
                attrs: {
                  "on-success": function(res) {
                    return _vm.handleSuccess(res, "legalPersonIdCardFace")
                  },
                  "before-upload": _vm.beforeUpload
                }
              },
              "el-upload",
              _vm.uploadData,
              false
            ),
            [
              _c("div", { staticClass: "uploadIcon" }, [
                _c("i", { staticClass: "el-icon-picture-outline" }),
                _c(
                  "div",
                  { staticClass: "btn", attrs: { flex: "main" } },
                  [
                    _c("el-button", { attrs: { type: "text" } }, [
                      _vm._v("商品视频")
                    ])
                  ],
                  1
                )
              ])
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }