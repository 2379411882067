import _objectSpread from "D:/Users/Admin/WebstormProjects/middle-east-pc/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
import util from '@/libs/util';
import chat from '@/components/d2-chat';
import { mapActions, mapGetters } from 'vuex';
window.yl = YL;
export default {
  name: 'app',
  watch: {
    '$i18n.locale': 'i18nHandle'
  },
  components: {
    chat: chat
  },
  created: function created() {
    this.i18nHandle(this.$i18n.locale); // this.sizeSet('small')
    // this.themeSet('d2')
  },
  computed: _objectSpread({}, mapGetters('d2admin', {
    isImShow: 'message/isImShow',
    isImLogin: 'message/isImLogin',
    isImFormId: 'message/isImFormId'
  })),
  methods: _objectSpread(_objectSpread({}, mapActions({
    sizeSet: 'd2admin/size/set',
    themeSet: 'd2admin/theme/set'
  })), {}, {
    i18nHandle: function i18nHandle(val, oldVal) {
      util.cookies.set('lang', val);
      document.querySelector('html').setAttribute('lang', val);
    }
  })
};