//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
var initForm = {
  goodsStatus: 1
};
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'goodsList',
        params: cloneDeep(initForm)
      }
    };
  },
  methods: {
    handleSearch: function handleSearch() {
      var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    },
    handleclose: function handleclose() {
      this.$emit('close');
    },
    confirm: function confirm(row) {
      this.$emit('confirm', row);
      this.$emit('update:show', false);
    }
  }
};