var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "uploadWrapper" },
    [
      _c(
        "vuedraggable",
        {
          staticClass: "vue-draggable",
          class: { single: _vm.isSingle, maxHidden: _vm.isMaxHidden },
          attrs: { tag: "div", draggable: ".draggable-item" },
          on: { start: _vm.onDragStart, end: _vm.onDragEnd },
          model: {
            value: _vm.imgList,
            callback: function($$v) {
              _vm.imgList = $$v
            },
            expression: "imgList"
          }
        },
        [
          _vm._l(_vm.imgList, function(item, index) {
            return _c(
              "div",
              {
                key: item + index,
                staticClass: "draggable-item",
                style: { width: _vm.width + "px", height: _vm.height + "px" }
              },
              [
                item
                  ? [
                      _c("el-image", {
                        attrs: {
                          src: item,
                          fit: "cover",
                          "preview-src-list": [item]
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "shadow",
                          on: {
                            click: function($event) {
                              return _vm.onRemoveHandler(index)
                            }
                          }
                        },
                        [_c("i", { staticClass: "el-icon-delete" })]
                      )
                    ]
                  : _c(
                      "div",
                      {
                        staticClass: "defalutImg",
                        attrs: { flex: "main:center cross:center dir:top" }
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon el-icon-camera d2-pb-5"
                        }),
                        _c("span", { staticStyle: { "font-size": "12px" } }, [
                          _vm._v(_vm._s(_vm.tradeList ? "上传图片" : "细节图"))
                        ])
                      ]
                    )
              ],
              2
            )
          }),
          _c(
            "el-upload",
            {
              ref: "uploadRef",
              staticClass: "uploadBox",
              style: { width: _vm.width + "px", height: _vm.height + "px" },
              attrs: {
                slot: "footer",
                action: "https://httpbin.org/post",
                headers: _vm.headers,
                accept: ".jpg,.jpeg,.png,.gif",
                "show-file-list": false,
                multiple: !_vm.isSingle,
                limit: _vm.limit,
                "before-upload": _vm.beforeUpload,
                "on-success": _vm.onSuccessUpload,
                "on-exceed": _vm.onExceed
              },
              slot: "footer"
            },
            [
              _c(
                "i",
                {
                  staticClass: "el-icon-plus uploadIcon",
                  style: { width: _vm.width + "px", height: _vm.height + "px" }
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isUploading,
                          expression: "isUploading"
                        }
                      ],
                      staticClass: "uploading"
                    },
                    [_vm._v("正在上传...")]
                  ),
                  !_vm.isUploading &&
                  _vm.limit &&
                  _vm.limit !== 99 &&
                  !_vm.isSingle
                    ? _c("span", { staticClass: "limitTxt" }, [
                        _vm._v("最多" + _vm._s(_vm.limit) + "张")
                      ])
                    : _vm._e()
                ]
              )
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }