var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "htmlText" },
    [
      _c("editor", {
        attrs: { id: _vm.id, init: _vm.initTinymce },
        model: {
          value: _vm.editorContent,
          callback: function($$v) {
            _vm.editorContent = $$v
          },
          expression: "editorContent"
        }
      }),
      _c(
        "el-upload",
        _vm._b(
          {
            staticClass: "btn",
            attrs: {
              multiple: true,
              "on-success": _vm.handleSuccess,
              "before-upload": _vm.beforeUpload
            }
          },
          "el-upload",
          _vm.uploadData,
          false
        ),
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-picture", type: "primary", size: "mini" }
            },
            [_vm._v("插入多张图片")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }