import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
var areaData = [{
  "code": "CN",
  "zh": "中国",
  "en": "China",
  "ru": "Китай",
  "children": [{
    "code": "110000",
    "zh": "北京市",
    "en": "Beijing",
    "ru": "Пекин",
    "children": [{
      "code": "110100",
      "zh": "北京市",
      "en": "Beijing",
      "ru": "Пекин"
    }]
  }, {
    "code": "120000",
    "zh": "天津市",
    "en": "Tianjin",
    "ru": "Тяньцзинь",
    "children": [{
      "code": "120100",
      "zh": "天津市",
      "en": "Tianjin",
      "ru": "Тяньцзинь"
    }]
  }, {
    "code": "130000",
    "zh": "河北省",
    "en": "Hebei",
    "ru": "Хэбэй",
    "children": [{
      "code": "130100",
      "zh": "石家庄市",
      "en": "Shijiazhuang",
      "ru": "Шицзячжуан"
    }, {
      "code": "130200",
      "zh": "唐山市",
      "en": "Tangshan",
      "ru": "Таншань"
    }, {
      "code": "130300",
      "zh": "秦皇岛市",
      "en": "Qinhuangdao",
      "ru": "Циньхуандао"
    }, {
      "code": "130400",
      "zh": "邯郸市",
      "en": "Handan",
      "ru": "Ханьдань"
    }, {
      "code": "130500",
      "zh": "邢台市",
      "en": "Xingtai",
      "ru": "Синтай"
    }, {
      "code": "130600",
      "zh": "保定市",
      "en": "Baoding",
      "ru": "Баодин"
    }, {
      "code": "130700",
      "zh": "张家口市",
      "en": "Zhangjiakou",
      "ru": "Чжанцзякоу"
    }, {
      "code": "130800",
      "zh": "承德市",
      "en": "Chengde",
      "ru": "Чэндэ"
    }, {
      "code": "130900",
      "zh": "沧州市",
      "en": "Cangzhou",
      "ru": "Цанчжоу"
    }, {
      "code": "131000",
      "zh": "廊坊市",
      "en": "Langfang",
      "ru": "Ландшафт"
    }, {
      "code": "131100",
      "zh": "衡水市",
      "en": "Hengshui",
      "ru": "Хэншуй"
    }]
  }, {
    "code": "140000",
    "zh": "山西省",
    "en": "Shanxi",
    "ru": "Провинция Шаньси",
    "children": [{
      "code": "140100",
      "zh": "太原市",
      "en": "Taiyuan",
      "ru": "Тайюань"
    }, {
      "code": "140200",
      "zh": "大同市",
      "en": "Datong",
      "ru": "Датун"
    }, {
      "code": "140300",
      "zh": "阳泉市",
      "en": "Yangquan",
      "ru": "Янцюань"
    }, {
      "code": "140400",
      "zh": "长治市",
      "en": "Changzhi",
      "ru": "Чанчжи"
    }, {
      "code": "140500",
      "zh": "晋城市",
      "en": "Jincheng",
      "ru": "Цзиньчэн"
    }, {
      "code": "140600",
      "zh": "朔州市",
      "en": "Shuozhou",
      "ru": "Шочжоу"
    }, {
      "code": "140700",
      "zh": "晋中市",
      "en": "Jinzhong",
      "ru": "Цзиньчжун"
    }, {
      "code": "140800",
      "zh": "运城市",
      "en": "Yuncheng",
      "ru": "Юньчэн"
    }, {
      "code": "140900",
      "zh": "忻州市",
      "en": "Xinzhou",
      "ru": "Синьчжоу"
    }, {
      "code": "141000",
      "zh": "临汾市",
      "en": "Linfen",
      "ru": "Линьфэнь"
    }, {
      "code": "141100",
      "zh": "吕梁市",
      "en": "Lvliang",
      "ru": "Лу Лян"
    }]
  }, {
    "code": "150000",
    "zh": "内蒙古自治区",
    "en": "Inner Mongolia",
    "ru": "Автономный район Внутренняя Монголия",
    "children": [{
      "code": "150100",
      "zh": "呼和浩特市",
      "en": "Hohhot",
      "ru": "Хух - Хото"
    }, {
      "code": "150200",
      "zh": "包头市",
      "en": "Baotou",
      "ru": "Баотоу"
    }, {
      "code": "150300",
      "zh": "乌海市",
      "en": "Wuhai",
      "ru": "Ухай"
    }, {
      "code": "150400",
      "zh": "赤峰市",
      "en": "Chifeng",
      "ru": "Чифэн"
    }, {
      "code": "150500",
      "zh": "通辽市",
      "en": "Tongliao",
      "ru": "Тунляо"
    }, {
      "code": "150600",
      "zh": "鄂尔多斯市",
      "en": "Ordos",
      "ru": "Ордос"
    }, {
      "code": "150700",
      "zh": "呼伦贝尔市",
      "en": "Hulunbuir",
      "ru": "Хулун Белл"
    }, {
      "code": "150800",
      "zh": "巴彦淖尔市",
      "en": "Bayan Nur",
      "ru": "Баяннур"
    }, {
      "code": "150900",
      "zh": "乌兰察布市",
      "en": "Wulanchabu",
      "ru": "Уланчаб"
    }, {
      "code": "152200",
      "zh": "兴安盟",
      "en": "Hinggan",
      "ru": "ХНП УНИТА"
    }, {
      "code": "152500",
      "zh": "锡林郭勒盟",
      "en": "Xilingol",
      "ru": "Лига Силингола"
    }, {
      "code": "152900",
      "zh": "阿拉善盟",
      "en": "Alxa",
      "ru": "Альянс Алашана"
    }]
  }, {
    "code": "210000",
    "zh": "辽宁省",
    "en": "Liaoning",
    "ru": "Ляонин",
    "children": [{
      "code": "210100",
      "zh": "沈阳市",
      "en": "Shenyang",
      "ru": "Шэньян"
    }, {
      "code": "210200",
      "zh": "大连市",
      "en": "Dalian",
      "ru": "Далянь"
    }, {
      "code": "210300",
      "zh": "鞍山市",
      "en": "Anshan",
      "ru": "Аньшань"
    }, {
      "code": "210400",
      "zh": "抚顺市",
      "en": "Fushun",
      "ru": "Фушунь"
    }, {
      "code": "210500",
      "zh": "本溪市",
      "en": "Benxi",
      "ru": "Бэн Крик"
    }, {
      "code": "210600",
      "zh": "丹东市",
      "en": "Dandong",
      "ru": "Дантон"
    }, {
      "code": "210700",
      "zh": "锦州市",
      "en": "Jinzhou",
      "ru": "Цзиньчжоу"
    }, {
      "code": "210800",
      "zh": "营口市",
      "en": "Yingkou",
      "ru": "Инкоу"
    }, {
      "code": "210900",
      "zh": "阜新市",
      "en": "Fuxin",
      "ru": "Фусинь"
    }, {
      "code": "211000",
      "zh": "辽阳市",
      "en": "Liaoyang",
      "ru": "Ляоян"
    }, {
      "code": "211100",
      "zh": "盘锦市",
      "en": "Panjin",
      "ru": "Пань Цзинь"
    }, {
      "code": "211200",
      "zh": "铁岭市",
      "en": "Tieling",
      "ru": "Железный хребет"
    }, {
      "code": "211300",
      "zh": "朝阳市",
      "en": "Chaoyang",
      "ru": "Восход солнца"
    }, {
      "code": "211400",
      "zh": "葫芦岛市",
      "en": "Huludao",
      "ru": "Хулудао"
    }]
  }, {
    "code": "220000",
    "zh": "吉林省",
    "en": "Jilin",
    "ru": "Провинция Цзилинь",
    "children": [{
      "code": "220100",
      "zh": "长春市",
      "en": "Zhangchun",
      "ru": "Чанчунь"
    }, {
      "code": "220200",
      "zh": "吉林市",
      "en": "Jilin",
      "ru": "Цзилинь"
    }, {
      "code": "220300",
      "zh": "四平市",
      "en": "Siping",
      "ru": "Четыре плоских"
    }, {
      "code": "220400",
      "zh": "辽源市",
      "en": "Liaoyuan",
      "ru": "Ляоюань"
    }, {
      "code": "220500",
      "zh": "通化市",
      "en": "Tonghua",
      "ru": "Тунхуа"
    }, {
      "code": "220600",
      "zh": "白山市",
      "en": "Baishan",
      "ru": "Белая гора"
    }, {
      "code": "220700",
      "zh": "松原市",
      "en": "Songyuan",
      "ru": "Мацухара"
    }, {
      "code": "220800",
      "zh": "白城市",
      "en": "Baicheng",
      "ru": "Белый город"
    }, {
      "code": "222400",
      "zh": "延边朝鲜族自治州",
      "en": "Yanbian",
      "ru": "Яньбянь Корейская автономная область"
    }]
  }, {
    "code": "230000",
    "zh": "黑龙江省",
    "en": "Heilongjiang",
    "ru": "Хэйлунцзян",
    "children": [{
      "code": "230100",
      "zh": "哈尔滨市",
      "en": "Harbin",
      "ru": "Харбин"
    }, {
      "code": "230200",
      "zh": "齐齐哈尔市",
      "en": "Qiqihar",
      "ru": "Цицикар"
    }, {
      "code": "230300",
      "zh": "鸡西市",
      "en": "Jixi",
      "ru": "Курис"
    }, {
      "code": "230400",
      "zh": "鹤岗市",
      "en": "Hegang",
      "ru": "Хэган"
    }, {
      "code": "230500",
      "zh": "双鸭山市",
      "en": "Shuangyashan",
      "ru": "Двойной Утиный холм"
    }, {
      "code": "230600",
      "zh": "大庆市",
      "en": "Daqing",
      "ru": "Дацин"
    }, {
      "code": "230700",
      "zh": "伊春市",
      "en": "Yichun",
      "ru": "Ичунь"
    }, {
      "code": "230800",
      "zh": "佳木斯市",
      "en": "Jiamusi",
      "ru": "Джамус"
    }, {
      "code": "230900",
      "zh": "七台河市",
      "en": "Qitaihe",
      "ru": "Цитай"
    }, {
      "code": "231000",
      "zh": "牡丹江市",
      "en": "Mudanjiang",
      "ru": "Муданьцзян"
    }, {
      "code": "231100",
      "zh": "黑河市",
      "en": "Heihe",
      "ru": "Чёрная река"
    }, {
      "code": "231200",
      "zh": "绥化市",
      "en": "Suihua",
      "ru": "Суйхуа"
    }, {
      "code": "232700",
      "zh": "大兴安岭地区",
      "en": "Da Hinggan Ling",
      "ru": "Район Большого Хингана"
    }]
  }, {
    "code": "310000",
    "zh": "上海市",
    "en": "Shanghai",
    "ru": "Шанхай",
    "children": [{
      "code": "310100",
      "zh": "上海城区",
      "en": "Shanghai",
      "ru": "Город Шанхай"
    }]
  }, {
    "code": "320000",
    "zh": "江苏省",
    "en": "Jiangsu",
    "ru": "В провинции Цзянсу",
    "children": [{
      "code": "320100",
      "zh": "南京市",
      "en": "Nanjing",
      "ru": "Нанкин"
    }, {
      "code": "320200",
      "zh": "无锡市",
      "en": "Wuxi",
      "ru": "Уси"
    }, {
      "code": "320300",
      "zh": "徐州市",
      "en": "Xuzhou",
      "ru": "Сюйчжоу"
    }, {
      "code": "320400",
      "zh": "常州市",
      "en": "Changzhou",
      "ru": "Чанчжоу"
    }, {
      "code": "320500",
      "zh": "苏州市",
      "en": "Suzhou",
      "ru": "Сучжоу"
    }, {
      "code": "320600",
      "zh": "南通市",
      "en": "Nantong",
      "ru": "Наньтун"
    }, {
      "code": "320700",
      "zh": "连云港市",
      "en": "Lianyungang",
      "ru": "Ляньюньган"
    }, {
      "code": "320800",
      "zh": "淮安市",
      "en": "Huai'an",
      "ru": "Хуайань"
    }, {
      "code": "320900",
      "zh": "盐城市",
      "en": "Yancheng",
      "ru": "Солт Сити"
    }, {
      "code": "321000",
      "zh": "扬州市",
      "en": "Yangzhou",
      "ru": "Янчжоу"
    }, {
      "code": "321100",
      "zh": "镇江市",
      "en": "Zhenjiang",
      "ru": "Чжэньцзян"
    }, {
      "code": "321200",
      "zh": "泰州市",
      "en": "Taizhou",
      "ru": "Тайчжоу"
    }, {
      "code": "321300",
      "zh": "宿迁市",
      "en": "Suqian",
      "ru": "Сукиан"
    }]
  }, {
    "code": "330000",
    "zh": "浙江省",
    "en": "Zhejiang",
    "ru": "В провинции Чжэцзян",
    "children": [{
      "code": "330100",
      "zh": "杭州市",
      "en": "Hangzhou",
      "ru": "Ханчжоу"
    }, {
      "code": "330200",
      "zh": "宁波市",
      "en": "Ningbo",
      "ru": "Нинбо"
    }, {
      "code": "330300",
      "zh": "温州市",
      "en": "Wenzhou",
      "ru": "Вэньчжоу"
    }, {
      "code": "330400",
      "zh": "嘉兴市",
      "en": "Jiaxing",
      "ru": "Цзясин"
    }, {
      "code": "330500",
      "zh": "湖州市",
      "en": "Huzhou",
      "ru": "Хучжоу"
    }, {
      "code": "330600",
      "zh": "绍兴市",
      "en": "Shaoxing",
      "ru": "Шаосин"
    }, {
      "code": "330700",
      "zh": "金华市",
      "en": "Jinhua",
      "ru": "Ким Хва"
    }, {
      "code": "330800",
      "zh": "衢州市",
      "en": "Quzhou",
      "ru": "Цюйчжоу"
    }, {
      "code": "330900",
      "zh": "舟山市",
      "en": "Zhoushan",
      "ru": "Чжоушань"
    }, {
      "code": "331000",
      "zh": "台州市",
      "en": "Taizhou",
      "ru": "Тайчжоу"
    }, {
      "code": "331100",
      "zh": "丽水市",
      "en": "Lishui",
      "ru": "Лишуй"
    }]
  }, {
    "code": "340000",
    "zh": "安徽省",
    "en": "Anhui",
    "ru": "Провинция Аньхой",
    "children": [{
      "code": "340100",
      "zh": "合肥市",
      "en": "Hefei",
      "ru": "Хэфэй"
    }, {
      "code": "340200",
      "zh": "芜湖市",
      "en": "Wuhu",
      "ru": "Уху"
    }, {
      "code": "340300",
      "zh": "蚌埠市",
      "en": "Bangbu",
      "ru": "Будда"
    }, {
      "code": "340400",
      "zh": "淮南市",
      "en": "Huainan",
      "ru": "Хуайнань"
    }, {
      "code": "340500",
      "zh": "马鞍山市",
      "en": "Maanshan",
      "ru": "Аньшань"
    }, {
      "code": "340600",
      "zh": "淮北市",
      "en": "Huaibei",
      "ru": "Хуайбэй"
    }, {
      "code": "340700",
      "zh": "铜陵市",
      "en": "Tongling",
      "ru": "Тунлинг"
    }, {
      "code": "340800",
      "zh": "安庆市",
      "en": "Anqing",
      "ru": "Аньцин"
    }, {
      "code": "341000",
      "zh": "黄山市",
      "en": "Huangshan",
      "ru": "Хуаншань"
    }, {
      "code": "341100",
      "zh": "滁州市",
      "en": "Chuzhou",
      "ru": "Чучжоу"
    }, {
      "code": "341200",
      "zh": "阜阳市",
      "en": "Fuyang",
      "ru": "Фуян"
    }, {
      "code": "341300",
      "zh": "宿州市",
      "en": "Suzhou",
      "ru": "Сучжоу"
    }, {
      "code": "341500",
      "zh": "六安市",
      "en": "Lu'an",
      "ru": "Шесть Энн"
    }, {
      "code": "341600",
      "zh": "亳州市",
      "en": "Bozhou",
      "ru": "Бочжоу"
    }, {
      "code": "341700",
      "zh": "池州市",
      "en": "Chizhou",
      "ru": "Икэчжоу"
    }, {
      "code": "341800",
      "zh": "宣城市",
      "en": "Xuancheng",
      "ru": "Сюаньчэн"
    }]
  }, {
    "code": "350000",
    "zh": "福建省",
    "en": "Fujian",
    "ru": "Провинция Фуцзянь",
    "children": [{
      "code": "350100",
      "zh": "福州市",
      "en": "Fuzhou",
      "ru": "Фучжоу"
    }, {
      "code": "350200",
      "zh": "厦门市",
      "en": "Xiamen",
      "ru": "Сямынь"
    }, {
      "code": "350300",
      "zh": "莆田市",
      "en": "Putian",
      "ru": "Путянь"
    }, {
      "code": "350400",
      "zh": "三明市",
      "en": "Sanming",
      "ru": "Санмин"
    }, {
      "code": "350500",
      "zh": "泉州市",
      "en": "Quanzhou",
      "ru": "Цюаньчжоу"
    }, {
      "code": "350600",
      "zh": "漳州市",
      "en": "Zhangzhou",
      "ru": "Чжанчжоу"
    }, {
      "code": "350700",
      "zh": "南平市",
      "en": "Nanping",
      "ru": "Наньпин"
    }, {
      "code": "350800",
      "zh": "龙岩市",
      "en": "Longyan",
      "ru": "Дракон Рок"
    }, {
      "code": "350900",
      "zh": "宁德市",
      "en": "Ningde",
      "ru": "Ниндэ"
    }]
  }, {
    "code": "360000",
    "zh": "江西省",
    "en": "Jiangxi",
    "ru": "Провинция Цзянси",
    "children": [{
      "code": "360100",
      "zh": "南昌市",
      "en": "Nanchang",
      "ru": "Наньчан"
    }, {
      "code": "360200",
      "zh": "景德镇市",
      "en": "Jingdezhen",
      "ru": "Цзиндэчжэнь"
    }, {
      "code": "360300",
      "zh": "萍乡市",
      "en": "Pingxiang",
      "ru": "Пинсян"
    }, {
      "code": "360400",
      "zh": "九江市",
      "en": "Jiujiang",
      "ru": "Цзюцзян"
    }, {
      "code": "360500",
      "zh": "新余市",
      "en": "Xinyu",
      "ru": "Новый остаток"
    }, {
      "code": "360600",
      "zh": "鹰潭市",
      "en": "Yingtan",
      "ru": "Ястребиный Тан"
    }, {
      "code": "360700",
      "zh": "赣州市",
      "en": "Ganzhou",
      "ru": "Ганьчжоу"
    }, {
      "code": "360800",
      "zh": "吉安市",
      "en": "Ji'an",
      "ru": "Джиан"
    }, {
      "code": "360900",
      "zh": "宜春市",
      "en": "Yichun",
      "ru": "Ичунь"
    }, {
      "code": "361000",
      "zh": "抚州市",
      "en": "Fuzhou",
      "ru": "Фучжоу"
    }, {
      "code": "361100",
      "zh": "上饶市",
      "en": "Shangrao",
      "ru": "Шанграо"
    }]
  }, {
    "code": "370000",
    "zh": "山东省",
    "en": "Shandong",
    "ru": "Провинция Шаньдун",
    "children": [{
      "code": "370100",
      "zh": "济南市",
      "en": "Jinan",
      "ru": "Цзинань"
    }, {
      "code": "370200",
      "zh": "青岛市",
      "en": "Qingdao",
      "ru": "Циндао"
    }, {
      "code": "370300",
      "zh": "淄博市",
      "en": "Zibo",
      "ru": "Цзыбо"
    }, {
      "code": "370400",
      "zh": "枣庄市",
      "en": "Zaozhuang",
      "ru": "Цзаочжуан"
    }, {
      "code": "370500",
      "zh": "东营市",
      "en": "Dongying",
      "ru": "Восточный лагерь"
    }, {
      "code": "370600",
      "zh": "烟台市",
      "en": "Yantai",
      "ru": "Яньтай"
    }, {
      "code": "370700",
      "zh": "潍坊市",
      "en": "Weifang",
      "ru": "Вэйфан"
    }, {
      "code": "370800",
      "zh": "济宁市",
      "en": "Jining",
      "ru": "Цзинин"
    }, {
      "code": "370900",
      "zh": "泰安市",
      "en": "Tai'an",
      "ru": "Тайан"
    }, {
      "code": "371000",
      "zh": "威海市",
      "en": "Weihai",
      "ru": "Вэйхай"
    }, {
      "code": "371100",
      "zh": "日照市",
      "en": "Rizhao",
      "ru": "Солнечный свет"
    }, {
      "code": "371200",
      "zh": "莱芜市",
      "en": "Laiwu",
      "ru": "Лай Ву"
    }, {
      "code": "371300",
      "zh": "临沂市",
      "en": "Linyi",
      "ru": "Линьи"
    }, {
      "code": "371400",
      "zh": "德州市",
      "en": "Dezhou",
      "ru": "Техас"
    }, {
      "code": "371500",
      "zh": "聊城市",
      "en": "Liaocheng",
      "ru": "Ляочэн"
    }, {
      "code": "371600",
      "zh": "滨州市",
      "en": "Binzhou",
      "ru": "Бинчжоу"
    }, {
      "code": "371700",
      "zh": "菏泽市",
      "en": "Heze",
      "ru": "Хезе"
    }]
  }, {
    "code": "410000",
    "zh": "河南省",
    "en": "Henan",
    "ru": "Хэнань",
    "children": [{
      "code": "410100",
      "zh": "郑州市",
      "en": "Zhengzhou",
      "ru": "Чжэнчжоу"
    }, {
      "code": "410200",
      "zh": "开封市",
      "en": "Kaifeng",
      "ru": "Кайфэн"
    }, {
      "code": "410300",
      "zh": "洛阳市",
      "en": "Luoyang",
      "ru": "Лоян"
    }, {
      "code": "410400",
      "zh": "平顶山市",
      "en": "Pingdingshan",
      "ru": "Пиндиншань"
    }, {
      "code": "410500",
      "zh": "安阳市",
      "en": "Anyang",
      "ru": "Аньян"
    }, {
      "code": "410600",
      "zh": "鹤壁市",
      "en": "Hebi",
      "ru": "Журавлевая стена"
    }, {
      "code": "410700",
      "zh": "新乡市",
      "en": "Xinxiang",
      "ru": "Новый поселок"
    }, {
      "code": "410800",
      "zh": "焦作市",
      "en": "Jiaozuo",
      "ru": "Цзяоцзуо"
    }, {
      "code": "410900",
      "zh": "濮阳市",
      "en": "Puyang",
      "ru": "Пу Ян"
    }, {
      "code": "411000",
      "zh": "许昌市",
      "en": "Xuchang",
      "ru": "Сюй Чан"
    }, {
      "code": "411100",
      "zh": "漯河市",
      "en": "Luohe",
      "ru": "Река"
    }, {
      "code": "411200",
      "zh": "三门峡市",
      "en": "Sanmenxia",
      "ru": "Саньмэнься"
    }, {
      "code": "411300",
      "zh": "南阳市",
      "en": "Nanyang",
      "ru": "Наньян"
    }, {
      "code": "411400",
      "zh": "商丘市",
      "en": "Shangqiu",
      "ru": "Шанцю"
    }, {
      "code": "411500",
      "zh": "信阳市",
      "en": "Xinyang",
      "ru": "Синьян"
    }, {
      "code": "411600",
      "zh": "周口市",
      "en": "Zhoukou",
      "ru": "Чжоукоу"
    }, {
      "code": "411700",
      "zh": "驻马店市",
      "en": "Zhumadian",
      "ru": "Чжумадянь"
    }, {
      "code": "419001",
      "zh": "济源市",
      "en": "Jiyuan",
      "ru": "Цзиюань"
    }]
  }, {
    "code": "420000",
    "zh": "湖北省",
    "en": "Hubei",
    "ru": "Провинция Хубэй",
    "children": [{
      "code": "420100",
      "zh": "武汉市",
      "en": "Wuhan",
      "ru": "Ухань"
    }, {
      "code": "420200",
      "zh": "黄石市",
      "en": "Huangshi",
      "ru": "Йеллоустон"
    }, {
      "code": "420300",
      "zh": "十堰市",
      "en": "Shiyan",
      "ru": "Шиян"
    }, {
      "code": "420500",
      "zh": "宜昌市",
      "en": "Yichang",
      "ru": "Ичан"
    }, {
      "code": "420600",
      "zh": "襄阳市",
      "en": "Xiangyang",
      "ru": "Сянъян"
    }, {
      "code": "420700",
      "zh": "鄂州市",
      "en": "Ezhou",
      "ru": "Эчжоу"
    }, {
      "code": "420800",
      "zh": "荆门市",
      "en": "Jingmen",
      "ru": "Цзинмэнь"
    }, {
      "code": "420900",
      "zh": "孝感市",
      "en": "Xiaogan",
      "ru": "Чувство сыновнего благочестия"
    }, {
      "code": "421000",
      "zh": "荆州市",
      "en": "Jingzhou",
      "ru": "Цзинчжоу"
    }, {
      "code": "421100",
      "zh": "黄冈市",
      "en": "Huanggang",
      "ru": "Хуанган"
    }, {
      "code": "421200",
      "zh": "咸宁市",
      "en": "Xianning",
      "ru": "Сяньнин"
    }, {
      "code": "421300",
      "zh": "随州市",
      "en": "Suizhou",
      "ru": "Суйчжоу"
    }, {
      "code": "422800",
      "zh": "恩施土家族苗族自治州",
      "en": "Enshi",
      "ru": "Эньштуцзя - Мяоский автономный округ"
    }, {
      "code": "429004",
      "zh": "仙桃市",
      "en": "Xiantao",
      "ru": "Феникс"
    }, {
      "code": "429005",
      "zh": "潜江市",
      "en": "Qianjiang",
      "ru": "Подводная река"
    }, {
      "code": "429006",
      "zh": "天门市",
      "en": "Tianmen",
      "ru": "Тяньмэнь"
    }, {
      "code": "429021",
      "zh": "神农架林区",
      "en": "Shennongjia",
      "ru": "Лесной район Шеннунцзя"
    }]
  }, {
    "code": "430000",
    "zh": "湖南省",
    "en": "Hunan",
    "ru": "Провинция Хунань",
    "children": [{
      "code": "430100",
      "zh": "长沙市",
      "en": "Changsha",
      "ru": "Чанша"
    }, {
      "code": "430200",
      "zh": "株洲市",
      "en": "Zhuzhou",
      "ru": "Чжучжоу"
    }, {
      "code": "430300",
      "zh": "湘潭市",
      "en": "Xiangtan",
      "ru": "Сянтань"
    }, {
      "code": "430400",
      "zh": "衡阳市",
      "en": "Hengyang",
      "ru": "Хэнъян"
    }, {
      "code": "430500",
      "zh": "邵阳市",
      "en": "Shaoyang",
      "ru": "Шао Янг"
    }, {
      "code": "430600",
      "zh": "岳阳市",
      "en": "Yueyang",
      "ru": "Юэян"
    }, {
      "code": "430700",
      "zh": "常德市",
      "en": "Changde",
      "ru": "Чандэ"
    }, {
      "code": "430800",
      "zh": "张家界市",
      "en": "Zhangjiajie",
      "ru": "Чжанцзяцзе"
    }, {
      "code": "430900",
      "zh": "益阳市",
      "en": "Yiyang",
      "ru": "Иян"
    }, {
      "code": "431000",
      "zh": "郴州市",
      "en": "Chenzhou",
      "ru": "Чэньчжоу"
    }, {
      "code": "431100",
      "zh": "永州市",
      "en": "Yongzhou",
      "ru": "Юнчжоу"
    }, {
      "code": "431200",
      "zh": "怀化市",
      "en": "Huaihua",
      "ru": "Хуайхуа"
    }, {
      "code": "431300",
      "zh": "娄底市",
      "en": "Loudi",
      "ru": "Луди"
    }, {
      "code": "433100",
      "zh": "湘西土家族苗族自治州",
      "en": "Xiangxi",
      "ru": "Хунси - Туцзя - Мяоский автономный округ"
    }]
  }, {
    "code": "440000",
    "zh": "广东省",
    "en": "Guangdong",
    "ru": "Провинция Гуандун",
    "children": [{
      "code": "440100",
      "zh": "广州市",
      "en": "Guangzhou",
      "ru": "Гуанчжоу"
    }, {
      "code": "440200",
      "zh": "韶关市",
      "en": "Shaoguan",
      "ru": "Шаогуань"
    }, {
      "code": "440300",
      "zh": "深圳市",
      "en": "Shenzhen",
      "ru": "Шэньчжэнь"
    }, {
      "code": "440400",
      "zh": "珠海市",
      "en": "Zhuhai",
      "ru": "Чжухай"
    }, {
      "code": "440500",
      "zh": "汕头市",
      "en": "Shantou",
      "ru": "Шаньтоу"
    }, {
      "code": "440600",
      "zh": "佛山市",
      "en": "Foshan",
      "ru": "Фошань"
    }, {
      "code": "440700",
      "zh": "江门市",
      "en": "Jiangmen",
      "ru": "Цзянмэнь"
    }, {
      "code": "440800",
      "zh": "湛江市",
      "en": "Zhanjiang",
      "ru": "Чжаньцзян"
    }, {
      "code": "440900",
      "zh": "茂名市",
      "en": "Maoming",
      "ru": "Маомин"
    }, {
      "code": "441200",
      "zh": "肇庆市",
      "en": "Zhaoqing",
      "ru": "Чжаоцин"
    }, {
      "code": "441300",
      "zh": "惠州市",
      "en": "Huizhou",
      "ru": "Хуэйчжоу"
    }, {
      "code": "441400",
      "zh": "梅州市",
      "en": "Meizhou",
      "ru": "Мэй"
    }, {
      "code": "441500",
      "zh": "汕尾市",
      "en": "Shanwei",
      "ru": "Шаньвэй"
    }, {
      "code": "441600",
      "zh": "河源市",
      "en": "Heyuan",
      "ru": "Источники реки"
    }, {
      "code": "441700",
      "zh": "阳江市",
      "en": "Yangjiang",
      "ru": "Янцзян"
    }, {
      "code": "441800",
      "zh": "清远市",
      "en": "Qingyuan",
      "ru": "Цинъюань"
    }, {
      "code": "441900",
      "zh": "东莞市",
      "en": "Dongguan",
      "ru": "Дунгуань"
    }, {
      "code": "442000",
      "zh": "中山市",
      "en": "Zhongshan",
      "ru": "Накаяма"
    }, {
      "code": "445100",
      "zh": "潮州市",
      "en": "Chaozhou",
      "ru": "Чаочжоу"
    }, {
      "code": "445200",
      "zh": "揭阳市",
      "en": "Jieyang",
      "ru": "Цзеян"
    }, {
      "code": "445300",
      "zh": "云浮市",
      "en": "Yunfu",
      "ru": "Облака плывут"
    }]
  }, {
    "code": "450000",
    "zh": "广西壮族自治区",
    "en": "Guangxi",
    "ru": "Гуанси - Чжуанский автономный район",
    "children": [{
      "code": "450100",
      "zh": "南宁市",
      "en": "Nanning",
      "ru": "Наньнин"
    }, {
      "code": "450200",
      "zh": "柳州市",
      "en": "Liuzhou",
      "ru": "Лючжоу"
    }, {
      "code": "450300",
      "zh": "桂林市",
      "en": "Guilin",
      "ru": "Гуйлинь"
    }, {
      "code": "450400",
      "zh": "梧州市",
      "en": "Wuzhou",
      "ru": "Вучжоу"
    }, {
      "code": "450500",
      "zh": "北海市",
      "en": "Beihai",
      "ru": "Северное море"
    }, {
      "code": "450600",
      "zh": "防城港市",
      "en": "Fangchenggang",
      "ru": "Фанчэнган"
    }, {
      "code": "450700",
      "zh": "钦州市",
      "en": "Qinzhou",
      "ru": "Циньчжоу"
    }, {
      "code": "450800",
      "zh": "贵港市",
      "en": "Guigang",
      "ru": "Ваш порт"
    }, {
      "code": "450900",
      "zh": "玉林市",
      "en": "Yulin",
      "ru": "Юйлин"
    }, {
      "code": "451000",
      "zh": "百色市",
      "en": "Baise",
      "ru": "Сто цветов"
    }, {
      "code": "451100",
      "zh": "贺州市",
      "en": "Hezhou",
      "ru": "Хэчжоу"
    }, {
      "code": "451200",
      "zh": "河池市",
      "en": "Hechi",
      "ru": "Речной бассейн"
    }, {
      "code": "451300",
      "zh": "来宾市",
      "en": "Laibin",
      "ru": "Гость"
    }, {
      "code": "451400",
      "zh": "崇左市",
      "en": "Chongzuo",
      "ru": "Чондзо"
    }]
  }, {
    "code": "460000",
    "zh": "海南省",
    "en": "Hainan",
    "ru": "Хайнань",
    "children": [{
      "code": "460100",
      "zh": "海口市",
      "en": "Haikou",
      "ru": "Хайкоу"
    }, {
      "code": "460200",
      "zh": "三亚市",
      "en": "Sanya",
      "ru": "Санья"
    }, {
      "code": "460300",
      "zh": "三沙市",
      "en": "Sansha",
      "ru": "Три песка"
    }, {
      "code": "460400",
      "zh": "儋州市",
      "en": "Danzhou",
      "ru": "Даньчжоу"
    }, {
      "code": "469001",
      "zh": "五指山市",
      "en": "Wuzhishan",
      "ru": "Пять пальцев горы"
    }, {
      "code": "469002",
      "zh": "琼海市",
      "en": "Qionghai",
      "ru": "Джоан Хай"
    }, {
      "code": "469005",
      "zh": "文昌市",
      "en": "Wenchang",
      "ru": "Вэньчан"
    }, {
      "code": "469006",
      "zh": "万宁市",
      "en": "Wanning",
      "ru": "Ваннинг"
    }, {
      "code": "469007",
      "zh": "东方市",
      "en": "Dongfang",
      "ru": "Восток"
    }, {
      "code": "469021",
      "zh": "定安县",
      "en": "Dinganxian",
      "ru": "Уезд Динъань"
    }, {
      "code": "469022",
      "zh": "屯昌县",
      "en": "Tunchangxian",
      "ru": "Тунчан уезд"
    }, {
      "code": "469023",
      "zh": "澄迈县",
      "en": "Chengmaixian",
      "ru": "Округ Чэнмэй"
    }, {
      "code": "469024",
      "zh": "临高县",
      "en": "Lingaoxian",
      "ru": "Лингао"
    }, {
      "code": "469025",
      "zh": "白沙黎族自治县",
      "en": "Baisha",
      "ru": "Баишалийский автономный уезд"
    }, {
      "code": "469026",
      "zh": "昌江黎族自治县",
      "en": "Changjiang",
      "ru": "Чанцзян - Ли автономный уезд"
    }, {
      "code": "469027",
      "zh": "乐东黎族自治县",
      "en": "Ledong",
      "ru": "Ледонг - Лийский автономный округ"
    }, {
      "code": "469028",
      "zh": "陵水黎族自治县",
      "en": "Lingshui",
      "ru": "Лингшуйли автономный уезд"
    }, {
      "code": "469029",
      "zh": "保亭黎族苗族自治县",
      "en": "Baoting",
      "ru": "Баотин - Ли - Мяоский автономный уезд"
    }, {
      "code": "469030",
      "zh": "琼中黎族苗族自治县",
      "en": "Qiongzhong",
      "ru": "Цинчжунли - Мяоский автономный уезд"
    }]
  }, {
    "code": "500000",
    "zh": "重庆市",
    "en": "Zhongqing",
    "ru": "Чунцин",
    "children": [{
      "code": "500100",
      "zh": "重庆城区",
      "en": "Zhongqing",
      "ru": "Город Чунцин"
    }]
  }, {
    "code": "510000",
    "zh": "四川省",
    "en": "Sichuan",
    "ru": "Провинция Сычуань",
    "children": [{
      "code": "510100",
      "zh": "成都市",
      "en": "Chengdu",
      "ru": "Чэнду"
    }, {
      "code": "510300",
      "zh": "自贡市",
      "en": "Zigong",
      "ru": "Самогон"
    }, {
      "code": "510400",
      "zh": "攀枝花市",
      "en": "Panzhihua",
      "ru": "Паньихуа"
    }, {
      "code": "510500",
      "zh": "泸州市",
      "en": "Luzhou",
      "ru": "Лучжоу"
    }, {
      "code": "510600",
      "zh": "德阳市",
      "en": "Deyang",
      "ru": "Дэян"
    }, {
      "code": "510700",
      "zh": "绵阳市",
      "en": "Mianyang",
      "ru": "Мяньян"
    }, {
      "code": "510800",
      "zh": "广元市",
      "en": "Guangyuan",
      "ru": "Кванъюань"
    }, {
      "code": "510900",
      "zh": "遂宁市",
      "en": "Suining",
      "ru": "Суйнин"
    }, {
      "code": "511000",
      "zh": "内江市",
      "en": "Neijiang",
      "ru": "Нэйцзян"
    }, {
      "code": "511100",
      "zh": "乐山市",
      "en": "Leshan",
      "ru": "Лэшань"
    }, {
      "code": "511300",
      "zh": "南充市",
      "en": "Nanchong",
      "ru": "Наньчун"
    }, {
      "code": "511400",
      "zh": "眉山市",
      "en": "Meishan",
      "ru": "Мэйшань"
    }, {
      "code": "511500",
      "zh": "宜宾市",
      "en": "Yibin",
      "ru": "Ибин"
    }, {
      "code": "511600",
      "zh": "广安市",
      "en": "Guangan",
      "ru": "Куанг Ан"
    }, {
      "code": "511700",
      "zh": "达州市",
      "en": "Dazhou",
      "ru": "Дар"
    }, {
      "code": "511800",
      "zh": "雅安市",
      "en": "Ya'an",
      "ru": "Яан"
    }, {
      "code": "511900",
      "zh": "巴中市",
      "en": "Bazhong",
      "ru": "Бачжун"
    }, {
      "code": "512000",
      "zh": "资阳市",
      "en": "Ziyang",
      "ru": "Цзыян"
    }, {
      "code": "513200",
      "zh": "阿坝藏族羌族自治州",
      "en": "Aba",
      "ru": "Аба Тибетско - Цянский автономный округ"
    }, {
      "code": "513300",
      "zh": "甘孜藏族自治州",
      "en": "Ganzi",
      "ru": "Ганзи - Тибетский автономный округ"
    }, {
      "code": "513400",
      "zh": "凉山彝族自治州",
      "en": "Liangshan",
      "ru": "Ляншань - Ийский автономный округ"
    }]
  }, {
    "code": "520000",
    "zh": "贵州省",
    "en": "Guizhou",
    "ru": "Гуйчжоу",
    "children": [{
      "code": "520100",
      "zh": "贵阳市",
      "en": "Guiyang",
      "ru": "Гуйян"
    }, {
      "code": "520200",
      "zh": "六盘水市",
      "en": "Liupanshui",
      "ru": "Шесть капель воды"
    }, {
      "code": "520300",
      "zh": "遵义市",
      "en": "Zunyi",
      "ru": "Зуньи"
    }, {
      "code": "520400",
      "zh": "安顺市",
      "en": "Anshun",
      "ru": "Аншунь"
    }, {
      "code": "520500",
      "zh": "毕节市",
      "en": "Bijie",
      "ru": "Бицзе"
    }, {
      "code": "520600",
      "zh": "铜仁市",
      "en": "Tongren",
      "ru": "Кун Джин"
    }, {
      "code": "522300",
      "zh": "黔西南布依族苗族自治州",
      "en": "Qianxinan",
      "ru": "Гуйчжоуский Юго - Западный Буи - Мяоский автономный округ"
    }, {
      "code": "522600",
      "zh": "黔东南苗族侗族自治州",
      "en": "Qiandongnan",
      "ru": "Цяньдуннань - Мяоский автономный округ"
    }, {
      "code": "522700",
      "zh": "黔南布依族苗族自治州",
      "en": "Qiannan",
      "ru": "Цяньнань - Буй - Мяоский автономный округ"
    }]
  }, {
    "code": "530000",
    "zh": "云南省",
    "en": "Yunnan",
    "ru": "Провинция Юньнань",
    "children": [{
      "code": "530100",
      "zh": "昆明市",
      "en": "Kunming",
      "ru": "Куньмин"
    }, {
      "code": "530300",
      "zh": "曲靖市",
      "en": "Qujing",
      "ru": "Куйцзин"
    }, {
      "code": "530400",
      "zh": "玉溪市",
      "en": "Yuxi",
      "ru": "Нефритовый ручей"
    }, {
      "code": "530500",
      "zh": "保山市",
      "en": "Baoshan",
      "ru": "Баошань"
    }, {
      "code": "530600",
      "zh": "昭通市",
      "en": "Zhaotong",
      "ru": "Чжаотун"
    }, {
      "code": "530700",
      "zh": "丽江市",
      "en": "Lijiang",
      "ru": "Лицзян"
    }, {
      "code": "530800",
      "zh": "普洱市",
      "en": "Pu'er",
      "ru": "Пуэр"
    }, {
      "code": "530900",
      "zh": "临沧市",
      "en": "Lincang",
      "ru": "Линьцан"
    }, {
      "code": "532300",
      "zh": "楚雄彝族自治州",
      "en": "Chuxiong",
      "ru": "Чусюн - Ийский автономный округ"
    }, {
      "code": "532500",
      "zh": "红河哈尼族彝族自治州",
      "en": "Honghe",
      "ru": "Хунхэ - Хани - Ийский автономный округ"
    }, {
      "code": "532600",
      "zh": "文山壮族苗族自治州",
      "en": "Wenshan",
      "ru": "Вэньшань - Чжуанский и Мяоский автономные округа"
    }, {
      "code": "532800",
      "zh": "西双版纳傣族自治州",
      "en": "Xishuangbanna",
      "ru": "Сишуанбаньна Дайский автономный округ"
    }, {
      "code": "532900",
      "zh": "大理白族自治州",
      "en": "Dali",
      "ru": "Дали - Байский автономный округ"
    }, {
      "code": "533100",
      "zh": "德宏傣族景颇族自治州",
      "en": "Dehong",
      "ru": "Дехун Дайский Цзинпу автономный округ"
    }, {
      "code": "533300",
      "zh": "怒江傈僳族自治州",
      "en": "Nujiang",
      "ru": "Нуцзян - Лисуский автономный округ"
    }, {
      "code": "533400",
      "zh": "迪庆藏族自治州",
      "en": "Diqing",
      "ru": "Дицин Тибетский автономный округ"
    }]
  }, {
    "code": "540000",
    "zh": "西藏自治区",
    "en": "Tibet",
    "ru": "Тибетский автономный район",
    "children": [{
      "code": "540100",
      "zh": "拉萨市",
      "en": "Lhasa",
      "ru": "Лхаса"
    }, {
      "code": "540200",
      "zh": "日喀则市",
      "en": "Rikaze",
      "ru": "Шигадзе"
    }, {
      "code": "540300",
      "zh": "昌都市",
      "en": "Changdu",
      "ru": "Чанду"
    }, {
      "code": "540400",
      "zh": "林芝市",
      "en": "Linzhi",
      "ru": "Ньингчи"
    }, {
      "code": "540500",
      "zh": "山南市",
      "en": "Shannan",
      "ru": "Шаньнань"
    }, {
      "code": "540600",
      "zh": "那曲市",
      "en": "Naqu",
      "ru": "Та песня"
    }, {
      "code": "542500",
      "zh": "阿里地区",
      "en": "Ngari",
      "ru": "Район Али"
    }]
  }, {
    "code": "610000",
    "zh": "陕西省",
    "en": "Shaanxi",
    "ru": "Провинция Шэньси",
    "children": [{
      "code": "610100",
      "zh": "西安市",
      "en": "Xi'an",
      "ru": "Сиань"
    }, {
      "code": "610200",
      "zh": "铜川市",
      "en": "Tongchuan",
      "ru": "Тунчхон"
    }, {
      "code": "610300",
      "zh": "宝鸡市",
      "en": "Baoji",
      "ru": "Бродяга"
    }, {
      "code": "610400",
      "zh": "咸阳市",
      "en": "Xianyang",
      "ru": "Сяньян"
    }, {
      "code": "610500",
      "zh": "渭南市",
      "en": "Weinan",
      "ru": "Вэйнан"
    }, {
      "code": "610600",
      "zh": "延安市",
      "en": "Yan'an",
      "ru": "Яньань"
    }, {
      "code": "610700",
      "zh": "汉中市",
      "en": "Hanzhong",
      "ru": "Ханьчжун"
    }, {
      "code": "610800",
      "zh": "榆林市",
      "en": "Yulin",
      "ru": "Юлин"
    }, {
      "code": "610900",
      "zh": "安康市",
      "en": "Ankang",
      "ru": "Анкан"
    }, {
      "code": "611000",
      "zh": "商洛市",
      "en": "Shangluo",
      "ru": "Шанло"
    }]
  }, {
    "code": "620000",
    "zh": "甘肃省",
    "en": "Gansu",
    "ru": "Провинция Ганьсу",
    "children": [{
      "code": "620100",
      "zh": "兰州市",
      "en": "Lanzhou",
      "ru": "Ланьчжоу"
    }, {
      "code": "620200",
      "zh": "嘉峪关市",
      "en": "Jiayuguan",
      "ru": "Цзяюйгуань"
    }, {
      "code": "620300",
      "zh": "金昌市",
      "en": "Jinchang",
      "ru": "Ким Чан"
    }, {
      "code": "620400",
      "zh": "白银市",
      "en": "Baiyin",
      "ru": "Серебро"
    }, {
      "code": "620500",
      "zh": "天水市",
      "en": "Tianshui",
      "ru": "Тяньшуй"
    }, {
      "code": "620600",
      "zh": "武威市",
      "en": "Wuwei",
      "ru": "Вууи"
    }, {
      "code": "620700",
      "zh": "张掖市",
      "en": "Zhangye",
      "ru": "Чжанъе"
    }, {
      "code": "620800",
      "zh": "平凉市",
      "en": "Pingliang",
      "ru": "Пин Хён"
    }, {
      "code": "620900",
      "zh": "酒泉市",
      "en": "Jiuquan",
      "ru": "Цзюцюань"
    }, {
      "code": "621000",
      "zh": "庆阳市",
      "en": "Qingyang",
      "ru": "Кёнъян"
    }, {
      "code": "621100",
      "zh": "定西市",
      "en": "Dingxi",
      "ru": "Динси"
    }, {
      "code": "621200",
      "zh": "陇南市",
      "en": "Longnan",
      "ru": "Лонгнам"
    }, {
      "code": "622900",
      "zh": "临夏回族自治州",
      "en": "Linxia",
      "ru": "Линься - Хуэйский автономный округ"
    }, {
      "code": "623000",
      "zh": "甘南藏族自治州",
      "en": "Gannan",
      "ru": "Ганнань - Тибетский автономный округ"
    }]
  }, {
    "code": "630000",
    "zh": "青海省",
    "en": "Qinghai",
    "ru": "Цинхай",
    "children": [{
      "code": "630100",
      "zh": "西宁市",
      "en": "Xining",
      "ru": "Синин"
    }, {
      "code": "630200",
      "zh": "海东市",
      "en": "Haidong",
      "ru": "Хайдун"
    }, {
      "code": "632200",
      "zh": "海北藏族自治州",
      "en": "Haibei",
      "ru": "Хайбэй - Тибетский автономный округ"
    }, {
      "code": "632300",
      "zh": "黄南藏族自治州",
      "en": "Huangnan",
      "ru": "Хуаннань - Тибетский автономный округ"
    }, {
      "code": "632500",
      "zh": "海南藏族自治州",
      "en": "Hainan",
      "ru": "Хайнань - Тибетский автономный округ"
    }, {
      "code": "632600",
      "zh": "果洛藏族自治州",
      "en": "Guoluo",
      "ru": "Голо Тибетский автономный округ"
    }, {
      "code": "632700",
      "zh": "玉树藏族自治州",
      "en": "Yushu",
      "ru": "Юйшу - Тибетский автономный округ"
    }, {
      "code": "632800",
      "zh": "海西蒙古族藏族自治州",
      "en": "Haixi",
      "ru": "Хайси Монгольско - Тибетский автономный округ"
    }]
  }, {
    "code": "640000",
    "zh": "宁夏回族自治区",
    "en": "Ningxia",
    "ru": "Нинся - Хуэйский автономный район",
    "children": [{
      "code": "640100",
      "zh": "银川市",
      "en": "Yinchuan",
      "ru": "Иньчуань"
    }, {
      "code": "640200",
      "zh": "石嘴山市",
      "en": "Shizuishan",
      "ru": "Сидзуйшань"
    }, {
      "code": "640300",
      "zh": "吴忠市",
      "en": "Wuzhong",
      "ru": "У Чжун"
    }, {
      "code": "640400",
      "zh": "固原市",
      "en": "Guyuan",
      "ru": "Гуюань"
    }, {
      "code": "640500",
      "zh": "中卫市",
      "en": "Zhongwei",
      "ru": "Центральный защитник"
    }]
  }, {
    "code": "650000",
    "zh": "新疆维吾尔自治区",
    "en": "Xinjiang",
    "ru": "Синьцзян - Уйгурский автономный район",
    "children": [{
      "code": "650100",
      "zh": "乌鲁木齐市",
      "en": "Urumqi",
      "ru": "Урумчи"
    }, {
      "code": "650200",
      "zh": "克拉玛依市",
      "en": "Kelamayi",
      "ru": "Кламай"
    }, {
      "code": "650400",
      "zh": "吐鲁番市",
      "en": "Tulufan",
      "ru": "Турфан"
    }, {
      "code": "650500",
      "zh": "哈密市",
      "en": "Hami",
      "ru": "Хами"
    }, {
      "code": "652300",
      "zh": "昌吉回族自治州",
      "en": "Changji",
      "ru": "Чанцзи - Хуэйский автономный округ"
    }, {
      "code": "652700",
      "zh": "博尔塔拉蒙古自治州",
      "en": "Bortala",
      "ru": "Бортара Монгольский автономный округ"
    }, {
      "code": "652800",
      "zh": "巴音郭楞蒙古自治州",
      "en": "Bayingol",
      "ru": "Баин - Голенг - Монгольский автономный округ"
    }, {
      "code": "652900",
      "zh": "阿克苏地区",
      "en": "Aksu",
      "ru": "Регион Аксу"
    }, {
      "code": "653000",
      "zh": "克孜勒苏柯尔克孜自治州",
      "en": "Kizilsu Kirgiz",
      "ru": "Кызылсукыргызская автономная область"
    }, {
      "code": "653100",
      "zh": "喀什地区",
      "en": "Kashgar",
      "ru": "Кашгарская область"
    }, {
      "code": "653200",
      "zh": "和田地区",
      "en": "Hotan",
      "ru": "Район Вада"
    }, {
      "code": "654000",
      "zh": "伊犁哈萨克自治州",
      "en": "Ili",
      "ru": "Или Казахская автономная область"
    }, {
      "code": "654200",
      "zh": "塔城地区",
      "en": "Tarbagatay",
      "ru": "Район Тачэн"
    }, {
      "code": "654300",
      "zh": "阿勒泰地区",
      "en": "Altay",
      "ru": "Регион Алтая"
    }, {
      "code": "659001",
      "zh": "石河子市",
      "en": "Shihezi",
      "ru": "Исикаки"
    }, {
      "code": "659002",
      "zh": "阿拉尔市",
      "en": "Alaer",
      "ru": "Алар"
    }, {
      "code": "659003",
      "zh": "图木舒克市",
      "en": "Tumushuke",
      "ru": "Тумушук"
    }, {
      "code": "659004",
      "zh": "五家渠市",
      "en": "Wujiaqu",
      "ru": "Пять каналов"
    }, {
      "code": "659005",
      "zh": "北屯市",
      "en": "Beitun",
      "ru": "Бэйтун"
    }, {
      "code": "659006",
      "zh": "铁门关市",
      "en": "Tiemenguan",
      "ru": "Железные ворота закрыты"
    }, {
      "code": "659007",
      "zh": "双河市",
      "en": "Shuanghe",
      "ru": "Две реки"
    }, {
      "code": "659008",
      "zh": "可克达拉市",
      "en": "Cocodala",
      "ru": "Кокдара"
    }, {
      "code": "659009",
      "zh": "昆玉市",
      "en": "Kunyu",
      "ru": "Куин Ю"
    }, {
      "code": "659010",
      "zh": "胡杨河市",
      "en": "Huyanghe",
      "ru": "Река Тополя"
    }, {
      "code": "659011",
      "zh": "新星市",
      "en": "Xinxing",
      "ru": "Новая звезда"
    }, {
      "code": "659012",
      "zh": "白杨市",
      "en": "Baiyang",
      "ru": "Тополь"
    }]
  }, {
    "code": "710000",
    "zh": "台湾省",
    "en": "Taiwan",
    "ru": "Провинция Тайвань",
    "children": [{
      "code": "710100",
      "zh": "台湾省",
      "en": "Taiwan",
      "ru": "Провинция Тайвань"
    }]
  }, {
    "code": "810000",
    "zh": "香港特别行政区",
    "en": "Hong Kong",
    "ru": "Особый административный район Гонконг",
    "children": [{
      "code": "810100",
      "zh": "香港城区",
      "en": "Hong Kong",
      "ru": "Городские районы Гонконга"
    }]
  }, {
    "code": "820000",
    "zh": "澳门特别行政区",
    "en": "Macao",
    "ru": "Особый административный район Макао",
    "children": [{
      "code": "820100",
      "zh": "澳门城区",
      "en": "Macao",
      "ru": "Город Макао"
    }]
  }]
}, {
  "code": "UZB",
  "zh": "乌兹别克斯坦",
  "en": "Uzbekistan",
  "ru": "",
  "children": [{
    "code": "1",
    "zh": "塔什干",
    "en": "Toshkent",
    "ru": "",
    "children": [{
      "code": "1",
      "zh": "塔什干",
      "en": "Toshkent",
      "ru": ""
    }]
  }, {
    "code": "2",
    "zh": "安集延州",
    "en": "Andijon viloyati",
    "ru": "",
    "children": [{
      "code": "21",
      "zh": "安集延市",
      "en": "Andijon",
      "ru": ""
    }, {
      "code": "22",
      "zh": "阿萨卡",
      "en": "Asaka",
      "ru": ""
    }, {
      "code": "23",
      "zh": "",
      "en": "Baliqchi",
      "ru": ""
    }, {
      "code": "24",
      "zh": "",
      "en": "Buloqboshi",
      "ru": ""
    }, {
      "code": "25",
      "zh": "",
      "en": "Boʻz",
      "ru": ""
    }, {
      "code": "26",
      "zh": "",
      "en": "Jalolquduq",
      "ru": ""
    }, {
      "code": "27",
      "zh": "",
      "en": "Izboskan",
      "ru": ""
    }, {
      "code": "28",
      "zh": "",
      "en": "Marhamat",
      "ru": ""
    }, {
      "code": "29",
      "zh": "",
      "en": "Oltinkoʻl",
      "ru": ""
    }, {
      "code": "210",
      "zh": "",
      "en": "Paxtaobod",
      "ru": ""
    }, {
      "code": "211",
      "zh": "",
      "en": "Ulugʻnor",
      "ru": ""
    }, {
      "code": "212",
      "zh": "",
      "en": "Xoʻjaobod",
      "ru": ""
    }, {
      "code": "213",
      "zh": "沙赫里汉",
      "en": "Shahrixon",
      "ru": ""
    }, {
      "code": "214",
      "zh": "",
      "en": "Qoʻrgʻontepa",
      "ru": ""
    }]
  }]
}, {
  "code": "KAZ",
  "zh": "哈萨克斯坦",
  "en": "Kazakhstan",
  "ru": ""
}, {
  "code": "ARE",
  "zh": "阿拉伯联合酋长国",
  "en": "The United Arab Emirates",
  "ru": ""
}, {
  "code": "TKM",
  "zh": "土库曼斯坦",
  "en": "Turkmenistan",
  "ru": ""
}];
export default areaData;
export function invokeAreaName(code, code1, code2) {
  var addr = [];
  areaData.forEach(function (e) {
    if (e.code === code) {
      addr[0] = e.zh;
      e.children.forEach(function (e1) {
        if (e1.code === code1) {
          addr[1] = e1.zh;
          e1.children.forEach(function (e2) {
            if (e2.code === code2) {
              addr[2] = e2.zh;
            }
          });
        }
      });
    }
  });
  return addr;
}