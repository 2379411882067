import "core-js/modules/es.array.map";

/**
 * @description 创建菜单
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */
export function elMenuItem(h, menu) {
  var icon = null;
  if (menu.icon) icon = h("i", {
    "class": "fa fa-".concat(menu.icon)
  });else if (menu.iconSvg) icon = h("d2-icon-svg", {
    "attrs": {
      "name": menu.iconSvg
    }
  }); // else icon = <i class="fa"/>

  return h("el-menu-item", {
    "key": menu.path,
    "attrs": {
      "index": menu.path
    }
  }, [icon, h("span", {
    "slot": "title",
    "style": "font-size:14px"
  }, [menu.title || '未命名菜单'])]);
}
/**
 * @description 创建子菜单
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */

export function elSubmenu(h, menu) {
  var _this = this;

  var icon = null;
  if (menu.icon) icon = h("i", {
    "slot": "title",
    "class": "fa fa-".concat(menu.icon)
  });else if (menu.iconSvg) icon = h("d2-icon-svg", {
    "slot": "title",
    "attrs": {
      "name": menu.iconSvg
    }
  }); // else icon = <i slot="title" class="fa fa-folder-o"/>

  return h("el-submenu", {
    "key": menu.path,
    "attrs": {
      "index": menu.path
    }
  }, [icon, h("span", {
    "slot": "title",
    "style": "font-size:14px"
  }, [menu.title || '未命名菜单']), menu.children.map(function (child) {
    return createMenu.call(_this, h, child);
  })]);
}
/**
 * @description 在组件中调用此方法渲染菜单项目
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */

export function createMenu(h, menu) {
  if (menu.children === undefined) return elMenuItem.call(this, h, menu);
  return elSubmenu.call(this, h, menu);
}