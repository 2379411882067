var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_c("d2-page-head")], 1),
      _c(
        "div",
        { staticStyle: { width: "1000px" } },
        [
          _c(
            "el-card",
            { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
            [
              _c("div", { staticClass: "card-box" }, [
                _c("div", { staticClass: "left" }, [_vm._v("违规原因：")]),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.cardData.violationReason) }
                })
              ]),
              _c(
                "div",
                { staticClass: "btn-box" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { round: "", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.goRoute("editTrade", {
                            goodsId: _vm.goodsId
                          })
                        }
                      }
                    },
                    [_vm._v("去修改")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }