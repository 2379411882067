var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_c("d2-page-head")], 1),
      _c(
        "div",
        { staticClass: "card" },
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("div", [
                _c("span", { staticClass: "d2-mr" }, [
                  _vm._v("属性名称：" + _vm._s(_vm.list.attributeName))
                ]),
                _c("span", { staticClass: "d2-ml" }, [
                  _vm._v("在发布/编辑商品时，还可以自定义属性值名称哦")
                ])
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.clickAdd } },
                [_vm._v("添加属性值")]
              )
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, rules: _vm.rules, inline: "" }
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.list.items,
                    border: "",
                    stripe: "",
                    "header-cell-style": { background: "#F6F6F6" }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", align: "center" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "itemValue",
                      label: "属性值名称",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-form-item",
                              [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row.status,
                                      expression: "row.status"
                                    }
                                  ],
                                  attrs: { placeholder: "", clearable: "" },
                                  model: {
                                    value: row.itemValue,
                                    callback: function($$v) {
                                      _vm.$set(row, "itemValue", $$v)
                                    },
                                    expression: "row.itemValue"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !row.status,
                                        expression: "!row.status"
                                      }
                                    ]
                                  },
                                  [_vm._v(_vm._s(row.itemValue))]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "itemSort", label: "排序", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("el-input-number", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: row.status,
                                  expression: "row.status"
                                }
                              ],
                              attrs: { controls: false, clearable: "" },
                              model: {
                                value: row.itemSort,
                                callback: function($$v) {
                                  _vm.$set(row, "itemSort", $$v)
                                },
                                expression: "row.itemSort"
                              }
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.status,
                                    expression: "!row.status"
                                  }
                                ]
                              },
                              [_vm._v(_vm._s(row.itemSort))]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.status,
                                    expression: "!row.status"
                                  }
                                ],
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.$set(row, "status", true)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: row.status,
                                    expression: "row.status"
                                  }
                                ],
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.clickSave(row)
                                  }
                                }
                              },
                              [_vm._v("保存")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.sellAttributeItemId,
                                    expression: "!row.sellAttributeItemId"
                                  }
                                ],
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.clickBack(row)
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }