//
//
//
//
//
//
export default {
  meta: {
    title: '',
    auth: true
  },
  name: 'page-content',
  data: function data() {
    return {};
  }
};