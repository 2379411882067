var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { attrs: { shadow: "never" } },
    _vm._l(_vm.roleList, function(l, i) {
      return _c(
        "el-card",
        { key: i, staticClass: "d2-mb", attrs: { shadow: "never" } },
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { indeterminate: l.isIndeterminate },
                  on: {
                    change: function(e) {
                      return _vm.handleCheckChange(e, l)
                    }
                  },
                  model: {
                    value: l.check,
                    callback: function($$v) {
                      _vm.$set(l, "check", $$v)
                    },
                    expression: "l.check"
                  }
                },
                [_vm._v(_vm._s(l.authorityName))]
              )
            ],
            1
          ),
          _c(
            "el-checkbox-group",
            {
              on: {
                change: function(e) {
                  return _vm.handleCheckedCitiesChange(e, l)
                }
              },
              model: {
                value: l.positionAuthorities,
                callback: function($$v) {
                  _vm.$set(l, "positionAuthorities", $$v)
                },
                expression: "l.positionAuthorities"
              }
            },
            _vm._l(l.children, function(role, val) {
              return _c(
                "el-checkbox",
                { key: val, attrs: { label: role.authorityValue } },
                [_vm._v(_vm._s(role.authorityName))]
              )
            }),
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }