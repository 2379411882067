var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_c("d2-page-head")], 1),
      _c(
        "ul",
        { staticClass: "card" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.unReadMessage > 0,
                  expression: "unReadMessage>0"
                }
              ],
              staticClass: "readAll",
              on: { click: _vm.readAllMsg }
            },
            [_vm._v("标记所有为已读")]
          ),
          _vm._l(_vm.newsList, function(l, i) {
            return _c(
              "li",
              {
                key: i,
                staticClass: "body",
                on: {
                  click: function($event) {
                    return _vm.openDetail(l)
                  }
                }
              },
              [
                _c(
                  "el-card",
                  {
                    attrs: {
                      shadow: "never",
                      "body-style": { padding: "20px" }
                    }
                  },
                  [
                    _c("div", { staticClass: "header" }, [
                      !l.messageRead
                        ? _c("div", { staticClass: "dot" })
                        : _vm._e(),
                      _c("div", { staticClass: "tit" }, [
                        _vm._v(_vm._s(l.messageTitle))
                      ]),
                      _c("div", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm._f("formatDateTime")(l.createTime)))
                      ])
                    ]),
                    _c("div", {
                      staticClass: "conter",
                      domProps: { innerHTML: _vm._s(l.messageContent) }
                    })
                  ]
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }