var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_c("d2-page-head")], 1),
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "10px" } } },
        [
          _c(
            "el-button",
            {
              staticClass: "d2-mb-10",
              on: {
                click: function($event) {
                  return _vm.goRoute("addTransportModule")
                }
              }
            },
            [_vm._v("添加")]
          ),
          _c(
            "el-card",
            { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
            [
              _vm.tableData.length > 0
                ? _c("Collapse", {
                    attrs: { collapseData: _vm.tableData },
                    on: { clickDel: _vm.clickDel }
                  })
                : _c("el-empty", { attrs: { description: "暂无模板" } })
            ],
            1
          ),
          _c("d2-pagination", {
            attrs: { total: _vm.total },
            on: { pagination: _vm.pagination }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }