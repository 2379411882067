var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-layer",
    {
      attrs: { layer: _vm.layer },
      on: {
        "update:layer": function($event) {
          _vm.layer = $event
        },
        confirm: _vm.confirm
      }
    },
    [
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { indeterminate: _vm.isIndeterminateAll },
                  on: { change: _vm.handleCheckAllChange },
                  model: {
                    value: _vm.checkAll,
                    callback: function($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll"
                  }
                },
                [_vm._v("中国大陆")]
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.mainlandProvinces, function(l, i) {
              return _c("el-col", { key: i, attrs: { span: 6, offset: 0 } }, [
                _c(
                  "div",
                  { attrs: { flex: "main" } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: {
                          indeterminate: l.indeterminate,
                          checked: l.selected
                        },
                        on: {
                          change: function(e) {
                            return _vm.handleCheckChange(e, l)
                          }
                        },
                        model: {
                          value: l.selected,
                          callback: function($$v) {
                            _vm.$set(l, "selected", $$v)
                          },
                          expression: "l.selected"
                        }
                      },
                      [_vm._v(_vm._s(l.provinceName))]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom",
                          width: "600",
                          trigger: "click"
                        }
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          _vm._l(l.cities, function(city) {
                            return _c(
                              "el-col",
                              {
                                key: city.cityCode,
                                attrs: { span: 8, offset: 0 }
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { label: city.cityCode },
                                    on: {
                                      change: function($event) {
                                        return _vm.clickPop(l)
                                      }
                                    },
                                    model: {
                                      value: city.selected,
                                      callback: function($$v) {
                                        _vm.$set(city, "selected", $$v)
                                      },
                                      expression: "city.selected"
                                    }
                                  },
                                  [_vm._v(_vm._s(city.cityName))]
                                )
                              ],
                              1
                            )
                          }),
                          1
                        ),
                        _c("i", {
                          staticClass: "el-icon-caret-bottom d2-ml-10",
                          staticStyle: { cursor: "pointer" },
                          attrs: { slot: "reference" },
                          slot: "reference"
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "d2-mt", attrs: { shadow: "never" } },
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { indeterminate: _vm.isIndeterminateAlltwo },
                  on: { change: _vm.handleCheckAllChangetwo },
                  model: {
                    value: _vm.checkAlltwo,
                    callback: function($$v) {
                      _vm.checkAlltwo = $$v
                    },
                    expression: "checkAlltwo"
                  }
                },
                [_vm._v("中国港澳台")]
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.hmtProvinces, function(l, i) {
              return _c("el-col", { key: i, attrs: { span: 6, offset: 0 } }, [
                _c(
                  "div",
                  { attrs: { flex: "main" } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: {
                          indeterminate: l.indeterminate,
                          checked: l.selected
                        },
                        on: {
                          change: function(e) {
                            return _vm.handleCheckChange(e, l)
                          }
                        },
                        model: {
                          value: l.selected,
                          callback: function($$v) {
                            _vm.$set(l, "selected", $$v)
                          },
                          expression: "l.selected"
                        }
                      },
                      [_vm._v(_vm._s(l.provinceName))]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom",
                          width: "600",
                          trigger: "click"
                        }
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          _vm._l(l.cities, function(city) {
                            return _c(
                              "el-col",
                              {
                                key: city.cityCode,
                                attrs: { span: 8, offset: 0 }
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { label: city.cityCode },
                                    on: {
                                      change: function($event) {
                                        return _vm.clickPop(l)
                                      }
                                    },
                                    model: {
                                      value: city.selected,
                                      callback: function($$v) {
                                        _vm.$set(city, "selected", $$v)
                                      },
                                      expression: "city.selected"
                                    }
                                  },
                                  [_vm._v(_vm._s(city.cityName))]
                                )
                              ],
                              1
                            )
                          }),
                          1
                        ),
                        _c("i", {
                          staticClass: "el-icon-caret-bottom d2-ml-10",
                          staticStyle: { cursor: "pointer" },
                          attrs: { slot: "reference" },
                          slot: "reference"
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }