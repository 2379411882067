import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var initForm = {
  orderNo: '',
  goodsName: '',
  nickname: '',
  adminTab: '',
  replied: '',
  evaluateTimeRange: {
    start: '',
    end: ''
  },
  level: ''
};
import { cloneDeep } from 'lodash';
export default {
  meta: {
    title: '商品评价',
    auth: true,
    authorityValue: "comment-manage"
  },
  name: 'comment',
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'goodsEvaluateList',
        params: cloneDeep(initForm)
      }
    };
  },
  filters: {
    itemSell: function itemSell(e) {
      return e.map(function (v) {
        return v.itemValue;
      }).join('/');
    }
  },
  methods: {
    handleSelectionChange: function handleSelectionChange() {},
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    }
  }
};