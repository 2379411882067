var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _vm._l(_vm.tab, function(l, i) {
            return [
              _c(
                "el-tab-pane",
                {
                  key: i,
                  attrs: {
                    label: "" + l.label + (l.num ? "(" + l.num + ")" : ""),
                    name: l.type
                  }
                },
                [
                  _c(_vm.activeName, {
                    tag: "component",
                    staticStyle: { "margin-top": "20px" }
                  })
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }