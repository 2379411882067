import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find";
import Vue from 'vue';
/** 从列表中选出传入条目的对应item */

function ftValue2label(value, list) {
  var _list$find;

  var keyMap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    key: 'label',
    value: 'value'
  };
  return (_list$find = list.find(function (item) {
    return item[keyMap.value] === value;
  })) === null || _list$find === void 0 ? void 0 : _list$find[keyMap.key];
}

Vue.filter('ftValue2label', ftValue2label);
export { ftValue2label };