import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";

/*
*
* @param {Date()} date
* @param {Date()} fmt 'yyyy-MM-dd hh:mm:ss'||'hh:mm'||'yy-MM-dd' ......etc
* @return {String}
*
*/
function dateFormat(date) {
  var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'yyyy-MM-dd';
  var o = {
    'M+': date.getMonth() + 1,
    // 月份
    'd+': date.getDate(),
    // 日
    'h+': date.getHours(),
    // 小时
    'm+': date.getMinutes(),
    // 分
    's+': date.getSeconds(),
    // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3),
    // 季度
    S: date.getMilliseconds() // 毫秒

  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));

  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
  }

  return fmt;
}

function stampFormat(st) {
  var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'yyyy-MM-dd hh:mm';

  // st:时间戳
  if (String(st).length < 12) {
    st = st * 1000;
  }

  var date = new Date(st);
  return dateFormat(date, fmt);
}

function countDownObj(sTamp) {
  // 倒计时输出,sTamp是毫秒(目标时间-目前时间，毫秒单位)
  var seconds = parseInt(sTamp / 1000);
  var s, m, h, d, ms, allh;

  if (seconds <= 0) {
    return {
      seconds: 0,
      d: '00',
      h: '00',
      m: '00',
      s: '00',
      ms: '0'
    };
  }

  d = parseInt(seconds / (24 * 60 * 60));
  h = d > 0 ? parseInt(seconds / (60 * 60) % 24) : parseInt(seconds / (60 * 60));
  m = h > 0 || d > 0 ? parseInt(seconds / 60 % 60) : parseInt(seconds / 60);
  s = m > 0 || h > 0 || d > 0 ? parseInt(seconds % 60) : seconds;
  allh = h + d * 24 || '00';
  ms = sTamp % 1000 / 100;
  return {
    seconds: seconds,
    // 所有秒
    d: d >= 10 ? d : '0' + d,
    // 天数
    allh: allh,
    // 所有小时
    h: h >= 10 ? h : '0' + h,
    // 减去天数后剩下多少小时
    m: m >= 10 ? m : '0' + m,
    // 减去天数，小时后的分钟数
    s: s >= 10 ? s : '0' + s,
    // 减去天数，小时，分钟后的秒数
    ms: ms // 减去天数，小时，分钟,秒后的豪秒数

  };
}

export default {
  dateFormat: dateFormat,
  stampFormat: stampFormat,
  countDownObj: countDownObj
};