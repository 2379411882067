var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "选择商品", visible: _vm.show, width: "80%" },
      on: {
        "update:visible": function($event) {
          _vm.show = $event
        },
        close: _vm.handleclose
      }
    },
    [
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _vm._v("> "),
          _c(
            "el-form-item",
            { attrs: { label: "商品名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入商品名称" },
                model: {
                  value: _vm.form.goodsName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsName", $$v)
                  },
                  expression: "form.goodsName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入商品编号" },
                model: {
                  value: _vm.form.goodsNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsNo", $$v)
                  },
                  expression: "form.goodsNo"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request, height: "50vh" } },
        [
          _c("el-table-column", {
            attrs: { "min-width": "400", label: "商品信息" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("goods-card", { attrs: { row: row } })]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.confirm(row)
                          }
                        }
                      },
                      [_vm._v("选择")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }