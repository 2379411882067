import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";

/**
 * @Description: 一些工具示例
 */
import { Message } from 'element-ui';
/**
 * 数字存储大小格式化
 * @param {number} num 存储大小 单位：Byte
 * @param {number} digits 保留几位小数
 * @return {string} 2MB
 */

function toStorage(num, digits) {
  digits = digits || 2;

  if (num < 1024) {
    return num + 'B';
  }

  num = num * 1000 / 1024;
  var si = [{
    value: 1e18,
    symbol: 'E'
  }, {
    value: 1e15,
    symbol: 'P'
  }, {
    value: 1e12,
    symbol: 'T'
  }, {
    value: 1e9,
    symbol: 'G'
  }, {
    value: 1e6,
    symbol: 'M'
  }, {
    value: 1e3,
    symbol: 'K'
  }];

  for (var i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol + 'B';
    }
  }
}
/**
 * 图片大小校验
 * @param {file} file el-upload文件对象
 * @param {number} size 限制的文件大小(kb) 默认10M
 */


function validImgUpload(file, size) {
  size = +size || 10240;
  var isSizeOut = file.size / 1024 > size;

  if (isSizeOut) {
    Message.error('上传图片大小不能超过' + toStorage(size * 1024));
  }

  return !isSizeOut;
}
/**
 * 创建唯一的字符串
 * @return {string} ojgdvbvaua40
 */


function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}

export default {
  toStorage: toStorage,
  validImgUpload: validImgUpload,
  createUniqueString: createUniqueString
};