var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_vm._v(" 实时概况 ")]),
      _c(
        "el-card",
        {
          staticClass: "d2-mb",
          attrs: { shadow: "never", "body-style": { padding: "20px" } }
        },
        [
          _c("div", { attrs: { flex: "main:justify" } }, [
            _c("div", { staticStyle: { "font-size": "20px" } }, [
              _vm._v(" 实时概况"),
              _c("i", {
                staticClass: "el-icon-timer d2-ml",
                staticStyle: { "font-size": "12px" }
              }),
              _vm._v(" "),
              _c("span", { staticStyle: { "font-size": "12px" } }, [
                _vm._v(
                  "更新时间: " + _vm._s(_vm._f("formatDateTime")(new Date()))
                )
              ])
            ])
          ])
        ]
      ),
      _c("CenterHome", { ref: "CenterHome", staticClass: "d2-mb" }),
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("b", [_vm._v("今日走势")])
          ]),
          _c(
            "el-descriptions",
            { attrs: { border: "", column: 1 } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "浏览行为" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.radioForm.visitors,
                        callback: function($$v) {
                          _vm.$set(_vm.radioForm, "visitors", $$v)
                        },
                        expression: "radioForm.visitors"
                      }
                    },
                    [_vm._v("访客数")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.radioForm.browses,
                        callback: function($$v) {
                          _vm.$set(_vm.radioForm, "browses", $$v)
                        },
                        expression: "radioForm.browses"
                      }
                    },
                    [_vm._v("浏览量")]
                  )
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "成交转化" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.radioForm.orderConsumers,
                        callback: function($$v) {
                          _vm.$set(_vm.radioForm, "orderConsumers", $$v)
                        },
                        expression: "radioForm.orderConsumers"
                      }
                    },
                    [_vm._v("成交客户数")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.radioForm.orders,
                        callback: function($$v) {
                          _vm.$set(_vm.radioForm, "orders", $$v)
                        },
                        expression: "radioForm.orders"
                      }
                    },
                    [_vm._v("成交单量")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.radioForm.orderGoods,
                        callback: function($$v) {
                          _vm.$set(_vm.radioForm, "orderGoods", $$v)
                        },
                        expression: "radioForm.orderGoods"
                      }
                    },
                    [_vm._v("成交商品件数")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.radioForm.orderAmount,
                        callback: function($$v) {
                          _vm.$set(_vm.radioForm, "orderAmount", $$v)
                        },
                        expression: "radioForm.orderAmount"
                      }
                    },
                    [_vm._v("成交金额")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("ve-line", { attrs: { data: _vm.chartData } })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }