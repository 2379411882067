var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", {
        attrs: { slot: "header", title: _vm.title },
        slot: "header"
      }),
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
        [
          _c(
            "div",
            { staticStyle: { width: "500px" } },
            [
              _c(
                "el-form-item",
                { attrs: { required: "", label: "选择商品" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSelectGood }
                    },
                    [_vm._v("请选择商品")]
                  ),
                  _vm.form.goodsName
                    ? _c("span", { staticClass: "d2-ml-20" }, [
                        _vm._v("商品名称：" + _vm._s(_vm.form.goodsName))
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品原价", required: "" } },
                [
                  _vm.form.goodsPrice
                    ? _c("span", [_vm._v(_vm._s(_vm.form.goodsPrice) + "$")])
                    : _vm._e()
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "秒杀价格", required: "" } },
                [
                  _c("el-input-number", {
                    model: {
                      value: _vm.form.seckillPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "seckillPrice", $$v)
                      },
                      expression: "form.seckillPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "截止时间", required: "" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime"
                    },
                    model: {
                      value: _vm.form.seckillEndTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "seckillEndTime", $$v)
                      },
                      expression: "form.seckillEndTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", required: "" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20 },
                    model: {
                      value: _vm.form.seckillSort,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "seckillSort", $$v)
                      },
                      expression: "form.seckillSort"
                    }
                  }),
                  _c("p", { staticClass: "yl-tip" }, [
                    _vm._v("数字越小越靠前，数字相同则最新添加的在前面")
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.goBack()
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("select-goods", {
        attrs: { show: _vm.showSelectGoods },
        on: {
          "update:show": function($event) {
            _vm.showSelectGoods = $event
          },
          close: function($event) {
            _vm.showSelectGoods = false
          },
          confirm: _vm.handleSelectConfirm
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }