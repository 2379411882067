var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c(
        "el-button",
        {
          staticClass: "d2-mb-10",
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.goRoute("add-deliver-address")
            }
          }
        },
        [_vm._v("新增出库地址")]
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { prop: "date", label: "所在地址" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(_vm.getAreaName(row)) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "details", label: "详细地址" }
          }),
          _c("el-table-column", {
            attrs: { prop: "postCode", label: "邮编", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "phoneNumber", label: "联系电话", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "contactName", label: "发货人姓名", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "defaultAddress", label: "默认", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.defaultAddress ? "是" : "否") + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "address", label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.goRoute("edit-delivery-address", {
                              addressId: row.addressId
                            })
                          }
                        }
                      },
                      [_vm._v("修改")]
                    ),
                    _c("span", { staticClass: "d2-pl-10" }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.clickDel({ addressId: row.addressId })
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }