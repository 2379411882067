import "core-js/modules/es.number.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
//
//
//
//
//
//
//
//
export default {
  props: {
    item: {
      type: Object
    }
  },
  data: function data() {
    return {
      startNum: '',
      endNum: ''
    };
  },
  computed: {
    startEndNum: function startEndNum() {
      var startNum = this.startNum,
          endNum = this.endNum;
      return {
        startNum: startNum,
        endNum: endNum
      };
    }
  },
  methods: {
    empy: function empy(s, e) {
      return {
        start: s,
        end: e
      };
    },
    resetFields: function resetFields() {
      this.startNum = '';
      this.endNum = '';
    },
    handleChangeInput: function handleChangeInput(key) {
      return this.handleOnInput(this[key], key);
    },
    handleOnInput: function handleOnInput(val, key) {
      isNaN(val) && (this[key] = val.replace(/[^0-9]/gi, ''));
      !isNaN(val) && Number(val) <= 0 ? this[key] = val.replace(/[^0-9]/gi, '') : this[key];
    }
  },
  watch: {
    startEndNum: {
      handler: function handler(val) {
        var startNum = val.startNum,
            endNum = val.endNum;
        this.$attrs.searchForm[this.item.value] = this.empy(startNum, endNum);
      }
    }
  }
};