var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-radio-group",
    {
      staticClass: "base-radio-group",
      on: { change: _vm.handleChange },
      model: {
        value: _vm.radio,
        callback: function($$v) {
          _vm.radio = $$v
        },
        expression: "radio"
      }
    },
    [
      _vm.childrenType === "button"
        ? _vm._l(_vm.options, function(item, index) {
            return _c(
              "el-radio-button",
              { key: index, attrs: { label: item.value } },
              [_vm._v(_vm._s(item.label))]
            )
          })
        : _vm._l(_vm.options, function(item, index) {
            return _c(
              "el-radio",
              { key: index, attrs: { label: item.value } },
              [_vm._v(_vm._s(item.label))]
            )
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }