//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'goods-card',
  props: {
    row: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {};
  },
  methods: {// toNewBlank(){
    //   let href=window.location.href   //当前页面域名
    //   let params=`editTrade?type=100`   //跳转页面及传参
    //   let url = href.replace('selling',params)   //替换
    //   console.log('我是新路径',url)
    //   window.open(url,'_blank')
    // }
  },
  mounted: function mounted() {// console.log(this.row)
  }
};