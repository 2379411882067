var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._g(_vm._b({}, "el-select", _vm.$attrs, false), _vm.$listeners),
    _vm._l(_vm.item.children, function(l, i) {
      return _c("el-option", {
        key: i,
        attrs: { label: l.label, value: l.value }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }