var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "goods-card" }, [
    _c(
      "ul",
      { staticClass: "d2-p-0 d2-mt-0", attrs: { flex: "main:justify" } },
      [
        _c("li", { staticClass: "d2-mr-10" }, [
          _c("label", [_vm._v("商品编码:")]),
          _c("span", [_vm._v(_vm._s(_vm.row.goodsNo))])
        ]),
        _c("li", { staticClass: "d2-mr-10" }, [
          _c("label", [_vm._v("所属类目:")]),
          _c("span", [_vm._v(_vm._s(_vm.row.typeName))])
        ]),
        _c("li", { staticClass: "d2-mr-10" }, [
          _c("label", [_vm._v("商品货号:")]),
          _c("span", [_vm._v(_vm._s(_vm.row.goodsModel))])
        ])
      ]
    ),
    _c(
      "div",
      {
        staticClass: "d2-mt-10 d2-fl",
        staticStyle: { width: "100%" },
        attrs: { flex: "main" }
      },
      [
        _c("base-gallery", {
          attrs: {
            srcList: [_vm.row.goodsPrimaryImage],
            imgWidth: "75px",
            imgHeight: "75px"
          }
        }),
        _c("span", [_vm._v(" " + _vm._s(_vm.row.goodsName))]),
        _c("div", { staticClass: "flex" }, [_vm._t("default")], 2)
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }