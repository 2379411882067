import _objectSpread from "D:/Users/Admin/WebstormProjects/middle-east-pc/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';
export default {
  name: 'd2-header-color',
  data: function data() {
    return {
      predefine: ['#ff4500', '#ff8c00', '#ffd700', '#90ee90', '#00ced1', '#1e90ff', '#c71585']
    };
  },
  computed: _objectSpread({}, mapState('d2admin/color', ['value'])),
  watch: {
    value: function value(_value) {
      this.set(_value);
    }
  },
  methods: _objectSpread({}, mapActions('d2admin/color', ['set']))
};