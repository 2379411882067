import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.to-fixed";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: ['orderDetail'],
  data: function data() {
    return {
      tableData: []
    };
  },
  methods: {
    itemSellAttributesFn: function itemSellAttributesFn(row) {
      var itemSellAttributes = row.itemSellAttributes;
      var s = itemSellAttributes.map(function (v) {
        return " ".concat(v.itemValue);
      });
      return s.join('/');
    }
  },
  filters: {
    toFixed2: function toFixed2(e) {
      return e.toFixed(2);
    }
  }
};