import "core-js/modules/es.array.filter";
import Vue from 'vue';
import { cityMap } from '@/const/cityMap';
/** 从列表中选出传入条目的对应item */

function ftGetCityByCode(value) {
  return cityMap[value];
}

Vue.filter('ftGetCityByCode', ftGetCityByCode);
export { ftGetCityByCode };