var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
        [
          _c(
            "div",
            { staticStyle: { width: "500px" } },
            [
              _c(
                "el-form-item",
                { attrs: { required: "", label: "优惠卷名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.couponName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "couponName", $$v)
                      },
                      expression: "form.couponName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "满减金额", required: "" } },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("span", { staticStyle: { "margin-right": "15px" } }, [
                        _vm._v("满")
                      ]),
                      _c("el-input-number", {
                        attrs: { controls: false, precision: 2 },
                        model: {
                          value: _vm.form.fullAmount,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "fullAmount", $$v)
                          },
                          expression: "form.fullAmount"
                        }
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-left": "15px",
                            "margin-right": "15px"
                          }
                        },
                        [_vm._v("减")]
                      ),
                      _c("el-input-number", {
                        attrs: {
                          controls: false,
                          precision: 2,
                          max: _vm.form.fullAmount
                        },
                        model: {
                          value: _vm.form.saveAmount,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "saveAmount", $$v)
                          },
                          expression: "form.saveAmount"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "失效日期", required: "" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期"
                    },
                    model: {
                      value: _vm.form.expireTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "expireTime", $$v)
                      },
                      expression: "form.expireTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "截止日期", required: "" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期"
                    },
                    model: {
                      value: _vm.form.receiveEndTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "receiveEndTime", $$v)
                      },
                      expression: "form.receiveEndTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "限量份数", required: "" } },
                [
                  _c(
                    "el-input-number",
                    {
                      attrs: { controls: false, precision: 0, min: 0 },
                      model: {
                        value: _vm.form.couponCount,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "couponCount", $$v)
                        },
                        expression: "form.couponCount"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("份")])],
                    2
                  ),
                  _c("p", { staticClass: "yl-tip" }, [
                    _vm._v("说明：超限量后用户不可再领取和查看到这个优惠券")
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "使用说明" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20 },
                    model: {
                      value: _vm.form.couponInstruction,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "couponInstruction", $$v)
                      },
                      expression: "form.couponInstruction"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.goBack()
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }