var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      attrs: { betterScroll: "" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.clickSave } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("template", { slot: "header" }, [_vm._v(" 添加/编辑属性 ")]),
      _c("div", { staticClass: "card d2-mb" }, [
        _c(
          "div",
          { attrs: { flex: "main cross:center" } },
          [
            _c("span", { staticClass: "text-999" }, [_vm._v("所属分类：")]),
            _c(
              "el-breadcrumb",
              _vm._l(_vm.breadcrumb, function(l, i) {
                return _c("el-breadcrumb-item", { key: l }, [_vm._v(_vm._s(l))])
              }),
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "d2-mt",
            attrs: { flex: "main:justify cross:center" }
          },
          [
            _c(
              "div",
              { attrs: { flex: "main:justify cross:center" } },
              [
                _c("span", { staticStyle: { width: "130px" } }, [
                  _vm._v("销售属性名称")
                ]),
                _c("el-input", {
                  attrs: { label: "销售属性名称", clearable: "" },
                  model: {
                    value: _vm.form.attributeName,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "attributeName", $$v)
                    },
                    expression: "form.attributeName"
                  }
                })
              ],
              1
            ),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.clickAdd } },
              [_vm._v("添加属性值")]
            )
          ],
          1
        )
      ]),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.form.items,
            border: "",
            stripe: "",
            "header-cell-style": { background: "#F6F6F6" }
          }
        },
        [
          _c("el-table-column", { attrs: { type: "index", align: "center" } }),
          _c("el-table-column", {
            attrs: { prop: "itemValue", label: "属性值名称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: row.status,
                          expression: "row.status"
                        }
                      ],
                      staticStyle: { width: "400px" },
                      attrs: { placeholder: "", clearable: "" },
                      model: {
                        value: row.itemValue,
                        callback: function($$v) {
                          _vm.$set(row, "itemValue", $$v)
                        },
                        expression: "row.itemValue"
                      }
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !row.status,
                            expression: "!row.status"
                          }
                        ]
                      },
                      [_vm._v(_vm._s(row.itemValue))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "itemSort", label: "排序", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-input-number", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: row.status,
                          expression: "row.status"
                        }
                      ],
                      attrs: { controls: false, clearable: "" },
                      model: {
                        value: row.itemSort,
                        callback: function($$v) {
                          _vm.$set(row, "itemSort", $$v)
                        },
                        expression: "row.itemSort"
                      }
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !row.status,
                            expression: "!row.status"
                          }
                        ]
                      },
                      [_vm._v(_vm._s(row.itemSort))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !row.status,
                            expression: "!row.status"
                          }
                        ],
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$set(row, "status", true)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: row.status,
                            expression: "row.status"
                          }
                        ],
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.clickStatus($index)
                          }
                        }
                      },
                      [_vm._v("保存")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !row.sellAttributeItemId,
                            expression: "!row.sellAttributeItemId"
                          }
                        ],
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.clickBack($index)
                          }
                        }
                      },
                      [_vm._v("取消")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }