import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import express from '@/const/express';
import util from '@/libs/util';
export default {
  props: ['orderDetail'],
  data: function data() {
    return {
      activities: [],
      name: ''
    };
  },
  filters: {
    expressCompany: function expressCompany(code) {
      var name = '';
      express.forEach(function (e) {
        if (e.code === code) {
          name = e.company;
        }
      });
      return name;
    }
  },
  methods: {
    copyThat: function copyThat() {
      util.copyThat(this.orderDetail.expressNumber);
    }
  }
};