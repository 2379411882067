var sexs = [{
  label: '全部',
  value: ''
}, {
  label: '男',
  value: 1
}, {
  label: '女',
  value: 2
}];
export { sexs };