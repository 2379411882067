import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import _objectSpread from "D:/Users/Admin/WebstormProjects/middle-east-pc/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  computed: _objectSpread(_objectSpread({}, mapGetters('d2admin', {
    unreadCount: 'message/unreadCount'
  })), {}, {
    tooltipContent: function tooltipContent() {
      return this.unreadCount === 0 ? '没有消息' : "".concat(this.unreadCount, " \u6761\u6D88\u606F").concat(this.unreadCount > 0 ? '' : '');
    }
  }),
  methods: {
    handleClick: function handleClick() {
      var url = window.location.href.split('#')[0].slice(0, -1) + '#/chat';
      YL.open(url); // this.$router.push('/chat')
    }
  }
};