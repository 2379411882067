import { render, staticRenderFns } from "./index.page.vue?vue&type=template&id=5bc32ce7&scoped=true&"
import script from "./index.page.vue?vue&type=script&lang=js&"
export * from "./index.page.vue?vue&type=script&lang=js&"
import style0 from "./index.page.vue?vue&type=style&index=0&id=5bc32ce7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bc32ce7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Users\\Admin\\WebstormProjects\\middle-east-pc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5bc32ce7')) {
      api.createRecord('5bc32ce7', component.options)
    } else {
      api.reload('5bc32ce7', component.options)
    }
    module.hot.accept("./index.page.vue?vue&type=template&id=5bc32ce7&scoped=true&", function () {
      api.rerender('5bc32ce7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/distribution/address/index.page.vue"
export default component.exports