export default [{
  path: '/index',
  title: '用户列表',
  iconSvg: '用户列表',
  authorityValue: 'user-management'
}, {
  title: '商品管理',
  iconSvg: '商品管理',
  authorityValue: 'goods-management',
  children: [{
    path: '/category',
    title: '分类管理',
    authorityValue: 'category-manage'
  }, {
    path: '/attr',
    title: '商品属性',
    authorityValue: 'attr-manage'
  }, {
    path: '/selling',
    title: '销售中商品',
    authorityValue: 'goods-selling'
  }, {
    path: '/unsell',
    title: '仓库中商品',
    authorityValue: 'goods-un-sell'
  }, {
    path: '/comment',
    title: '评价管理',
    authorityValue: 'comment-manage'
  }]
}, {
  title: '商城活动',
  iconSvg: '商城管理',
  authorityValue: "activity-management",
  children: [{
    path: '/coupon',
    title: '满减优惠券',
    authorityValue: "coupon-manage"
  }, {
    path: '/seckill',
    title: '秒杀活动',
    authorityValue: "seckill-manage"
  }, {
    path: '/vip',
    title: 'VIP设置',
    authorityValue: "vip-manage"
  }]
}, {
  title: '订单管理',
  iconSvg: '订单管理',
  authorityValue: 'order-management',
  children: [{
    path: '/order',
    title: '订单列表',
    authorityValue: 'order-list'
  }, {
    path: '/print',
    title: '出库单打印',
    authorityValue: 'order-print'
  }]
}, {
  title: '配送管理',
  iconSvg: '配送管理',
  authorityValue: 'delivery-management',
  children: [{
    path: '/address',
    title: '发货地址管理',
    authorityValue: 'delivery-address-manage'
  }, {
    path: '/fees',
    title: '设置运费模板',
    authorityValue: 'delivery-fee-manage'
  }]
}, {
  title: '商城设置',
  iconSvg: '商城管理',
  authorityValue: "setting-management",
  children: [{
    path: '/banners',
    title: '轮播图',
    authorityValue: "banner-manage"
  }, {
    path: '/chosen',
    title: '精选商品',
    authorityValue: "chosen-manage"
  }, {
    path: '/recommends',
    title: '推荐商品',
    authorityValue: "recommend-manage"
  }]
}, {
  title: '财务管理',
  iconSvg: '财务结算',
  authorityValue: 'financial-management',
  children: [{
    path: '/bill',
    title: '日月账单',
    authorityValue: 'bill-list'
  }, {
    path: '/billDetail',
    title: '账单明细',
    authorityValue: 'bill-logs'
  }, {
    path: '/saveLog',
    title: '优惠明细',
    authorityValue: 'save-logs'
  }, {
    path: '/allBills',
    title: '收支明细',
    authorityValue: 'bill-all'
  }]
}, {
  title: '数据统计',
  iconSvg: '数据统计',
  authorityValue: 'statistics-management',
  children: [{
    path: '/realTime',
    title: '实时概况',
    authorityValue: 'statistics-realtime'
  }, {
    path: '/transaction',
    title: '交易分析',
    authorityValue: 'trade-analysis'
  }, {
    path: '/commodity',
    title: '商品明细',
    authorityValue: 'statistics-goods'
  }, {
    path: '/evaluate',
    title: '评价分析',
    authorityValue: 'comment-analysis'
  }]
}, {
  title: '账号管理',
  iconSvg: '账号管理',
  authorityValue: 'account-management',
  children: [{
    path: '/role',
    title: '角色管理',
    authorityValue: 'position-manage'
  }, {
    path: '/staff',
    title: '员工管理',
    authorityValue: 'staff-manage'
  }]
}];