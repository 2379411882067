function getTrueFalseOptions() {
  var options = [{
    label: '全部',
    value: ''
  }, {
    label: '是',
    value: true
  }, {
    label: '否',
    value: false
  }];
  return options;
}

export { getTrueFalseOptions };