var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
        [
          _c(
            "div",
            { attrs: { slot: "header", flex: "main:right" }, slot: "header" },
            [
              _c("range-date", {
                attrs: { clearable: true },
                on: { change: _vm.rangeDateChange }
              })
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 0 } },
            [
              _vm._l(_vm.newsList, function(l, i) {
                return [
                  _c("el-col", { key: i, attrs: { span: 6, offset: 0 } }, [
                    _c("div", { staticClass: "item" }, [
                      !l.hide
                        ? _c("div", { staticClass: "tit" }, [
                            _vm._v(_vm._s(l.tit))
                          ])
                        : _vm._e(),
                      !l.hide
                        ? _c("div", { staticClass: "centent" }, [
                            _vm._v(_vm._s(l.count) + " "),
                            i == 7 ? _c("span", [_vm._v("%")]) : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ])
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }