var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c(
        "el-form",
        { staticStyle: { "margin-bottom": "15px" } },
        [_c("el-button", { on: { click: _vm.toSave } }, [_vm._v("添加VIP")])],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { label: "VIP名称", prop: "vipName", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { label: "价格", prop: "vipPrice", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [_vm._v(" " + _vm._s(row.vipPrice) + "$/年")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "折扣比例（%）", prop: "vipDiscount" }
          }),
          _c("el-table-column", { attrs: { label: "排序", prop: "vipSort" } }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.toUpdate(row)
                          }
                        }
                      },
                      [_vm._v(" 修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.del(row)
                          }
                        }
                      },
                      [_vm._v(" 删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }