var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", {
        attrs: { slot: "header", title: _vm.title },
        slot: "header"
      }),
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
        [
          _c(
            "div",
            { staticStyle: { width: "500px" } },
            [
              _c(
                "el-form-item",
                { attrs: { required: "", label: "VIP名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.vipName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "vipName", $$v)
                      },
                      expression: "form.vipName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "价格（年）", required: "" } },
                [
                  _c("el-input-number", {
                    attrs: { controls: false, precision: 2 },
                    model: {
                      value: _vm.form.vipPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "vipPrice", $$v)
                      },
                      expression: "form.vipPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "折扣比例（%）", required: "" } },
                [
                  _c(
                    "el-input-number",
                    {
                      attrs: { controls: false, precision: 2, min: 0 },
                      model: {
                        value: _vm.form.vipDiscount,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "vipDiscount", $$v)
                        },
                        expression: "form.vipDiscount"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("%")])],
                    2
                  ),
                  _c("p", { staticClass: "yl-tip" }, [
                    _vm._v("说明：用户购买商品时，实付价格为原价乘以次比例")
                  ]),
                  _vm.form.vipDiscount || _vm.form.vipDiscount === 0
                    ? _c("p", { staticClass: "yl-tip" }, [
                        _vm._v(
                          " 例如，商品原价100$，VIP用户的价格是：" +
                            _vm._s(
                              ((100 * _vm.form.vipDiscount) / 100).toFixed(2)
                            ) +
                            "$"
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", required: "" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20 },
                    model: {
                      value: _vm.form.vipSort,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "vipSort", $$v)
                      },
                      expression: "form.vipSort"
                    }
                  }),
                  _c("p", { staticClass: "yl-tip" }, [
                    _vm._v("数字越小越靠前，数字相同则最新添加的在前面")
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.goBack()
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }