//
//
//
//
//
//
//
//
//
//
var img = require('@/assets/image/kf.png');

export default {
  name: 'd2-header-customerService',
  data: function data() {
    return {
      img: img
    };
  },
  methods: {
    goRoute: function goRoute() {
      this.isImFormIdSet('custom_service');
    }
  }
};