import orderAll from './orderAll.vue';
import stayPay from './stayPay.vue';
import stayHouse from './stayHouse.vue';
import inHouse from './inHouse.vue';
import inSuccess from './inSuccess.vue';
import inError from './inError.vue';
var arr = {
  orderAll: orderAll,
  stayPay: stayPay,
  stayHouse: stayHouse,
  inHouse: inHouse,
  inSuccess: inSuccess,
  inError: inError
};
export default arr;