var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._b(
      {
        staticStyle: { width: "400px" },
        attrs: { value: _vm.value, placeholder: "请选择", clearable: "" },
        on: { change: _vm.handleChange }
      },
      "el-select",
      _vm.$attrs,
      false
    ),
    _vm._l(_vm.options, function(item) {
      return _c("el-option", {
        key: "" + item.label + item.value,
        attrs: { label: item.label, value: item.value }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }