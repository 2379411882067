//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    item: {
      type: Object
    }
  },
  data: function data() {
    return {
      startTime: '',
      endTime: '',
      time: ''
    };
  },
  computed: {
    startEndTime: function startEndTime() {
      var value = this.value;
      return {
        value: value
      };
    },
    value: {
      get: function get(v) {
        return [this.startTime, this.endTime];
      },
      set: function set(v) {
        this.startTime = v ? v[0] : '';
        this.endTime = v ? v[1] : '';
      }
    }
  },
  methods: {
    empy: function empy(value) {
      return {
        start: value[0],
        end: value[1]
      };
    },
    resetFields: function resetFields() {
      this.startTime = '';
      this.endTime = '';
    }
  },
  watch: {
    startEndTime: {
      handler: function handler(val) {
        var value = val.value;
        this.$attrs.searchForm[this.item.value] = this.empy(value);
      }
    }
  }
};