var userTypes = [{
  label: '用户',
  value: 1
}, {
  label: '教练',
  value: 2
}, {
  label: '管理',
  value: 3
}];
export { userTypes };