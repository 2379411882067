var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c(
        "el-card",
        { staticClass: "d2-mb top", staticStyle: { "font-size": "14px" } },
        [
          _c("span", { staticClass: "ml-20" }, [
            _vm._v("首页“精选商品”右上方小图")
          ]),
          _c("p", { staticClass: "d2-m" }, [
            _vm._v("图片:建议上传图片尺寸362*216像素,大小不能超过300kb")
          ]),
          _c(
            "div",
            {
              staticClass: "upload-img-cell ml-20",
              on: {
                click: function($event) {
                  return _vm.onOpenUpload("top" + 1, _vm.form.rightTopImageUrl)
                }
              }
            },
            [
              _c(
                "el-image",
                { attrs: { fit: "contain", src: _vm.form.rightTopImageUrl } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "image-slot",
                      attrs: { slot: "error" },
                      slot: "error"
                    },
                    [
                      _c("el-image", {
                        staticStyle: { width: "50px", height: "50px" },
                        attrs: { src: _vm.uploadImg }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d2-mt", attrs: { flex: "main" } },
            [
              _c("span", { staticStyle: { width: "120px" } }, [
                _vm._v("填写商品编号：")
              ]),
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { placeholder: "" },
                model: {
                  value: _vm.form.rightTopTowardUrl,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "rightTopTowardUrl", $$v)
                  },
                  expression: "form.rightTopTowardUrl"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "el-card",
        { staticClass: "d2-mb top", staticStyle: { "font-size": "14px" } },
        [
          _c("span", { staticClass: "ml-20" }, [
            _vm._v("首页“精选商品”右下方小图")
          ]),
          _c("p", { staticClass: "d2-m" }, [
            _vm._v("图片:建议上传图片尺寸310*460像素,大小不能超过300kb")
          ]),
          _c(
            "div",
            {
              staticClass: "upload-img-cell ml-20",
              on: {
                click: function($event) {
                  return _vm.onOpenUpload(
                    "top" + 2,
                    _vm.form.rightBottomImageUrl
                  )
                }
              }
            },
            [
              _c(
                "el-image",
                {
                  attrs: { fit: "contain", src: _vm.form.rightBottomImageUrl }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "image-slot",
                      attrs: { slot: "error" },
                      slot: "error"
                    },
                    [
                      _c("el-image", {
                        staticStyle: { width: "50px", height: "50px" },
                        attrs: { src: _vm.uploadImg }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d2-mt", attrs: { flex: "main" } },
            [
              _c("span", { staticStyle: { width: "120px" } }, [
                _vm._v("填写商品编号：")
              ]),
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { placeholder: "" },
                model: {
                  value: _vm.form.rightBottomTowardUrl,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "rightBottomTowardUrl", $$v)
                  },
                  expression: "form.rightBottomTowardUrl"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._l(_vm.form.leftBanners, function(item, index) {
        return _c(
          "el-form",
          {
            key: item.$uid,
            attrs: { model: item, "label-width": "140px", rules: _vm.rules }
          },
          [
            _c(
              "el-card",
              { staticClass: "mb-20" },
              [
                _vm._v(" 序号：" + _vm._s(index + 1) + " "),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _vm._v(" 建议上传图片尺寸690*280像素,大小不能超过300kb "),
                    _vm.form.leftBanners.length > 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.onDel(index)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "轮播商品",
                      prop: "bannerUrl",
                      required: ""
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "upload-img-cell",
                        on: {
                          click: function($event) {
                            return _vm.onOpenUpload(index, item.imageUrl)
                          }
                        }
                      },
                      [
                        _c(
                          "el-image",
                          { attrs: { fit: "contain", src: item.imageUrl } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error"
                              },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px"
                                  },
                                  attrs: { src: _vm.uploadImg }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "轮播商品排序", prop: "bannerSort" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "100px" },
                      attrs: { placeholder: "", type: "number" },
                      model: {
                        value: item.bannerSort,
                        callback: function($$v) {
                          _vm.$set(item, "bannerSort", $$v)
                        },
                        expression: "item.bannerSort"
                      }
                    }),
                    _c("span", { staticClass: "ml-20" }, [
                      _vm._v("数值越小，排在越前面")
                    ])
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt-10",
                    attrs: { label: "填写商品编号：", prop: "towardUrl" }
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: { placeholder: "" },
                      model: {
                        value: item.towardUrl,
                        callback: function($$v) {
                          _vm.$set(item, "towardUrl", $$v)
                        },
                        expression: "item.towardUrl"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("发布")]
          ),
          _c(
            "el-button",
            {
              staticClass: "mt-20",
              attrs: { type: "primary", plain: "" },
              on: { click: _vm.onAdd }
            },
            [_vm._v("+新增轮播商品")]
          )
        ],
        1
      ),
      _vm.qnToken
        ? _c("base-upload", {
            ref: "upload",
            attrs: { token: _vm.qnToken, address: _vm.ossBaseUrl },
            on: { confirm: _vm.setCover }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }