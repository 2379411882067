var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-card",
        { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
        [
          _c(
            "div",
            { staticClass: "p-tit", attrs: { slot: "header" }, slot: "header" },
            [_c("span", [_vm._v(_vm._s(_vm.title))])]
          ),
          _c(
            "div",
            {
              staticStyle: { "padding-bottom": "200px" },
              attrs: { flex: "main:center cross:center" }
            },
            [
              _c(
                "el-form",
                {
                  ref: "elForm",
                  attrs: {
                    model: _vm.elForm,
                    rules: _vm.rules,
                    "label-width": "180px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类名称：", prop: "typeName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.elForm.typeName,
                          callback: function($$v) {
                            _vm.$set(_vm.elForm, "typeName", $$v)
                          },
                          expression: "elForm.typeName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.elForm.preTypeId
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "上级分类：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { disabled: "", placeholder: "请选择" },
                              model: {
                                value: _vm.upTypeName,
                                callback: function($$v) {
                                  _vm.upTypeName = $$v
                                },
                                expression: "upTypeName"
                              }
                            },
                            _vm._l(_vm.options, function(item) {
                              return _c("el-option", {
                                key: item.goodsTypeId,
                                attrs: {
                                  label: item.typeName,
                                  value: item.goodsTypeId
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序：", prop: "typeSort" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.elForm.typeSort,
                          callback: function($$v) {
                            _vm.$set(_vm.elForm, "typeSort", $$v)
                          },
                          expression: "elForm.typeSort"
                        }
                      })
                    ],
                    1
                  ),
                  !_vm.elForm.preTypeId
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否在首页显示分类：" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: true },
                              model: {
                                value: _vm.elForm.typeShow,
                                callback: function($$v) {
                                  _vm.$set(_vm.elForm, "typeShow", $$v)
                                },
                                expression: "elForm.typeShow"
                              }
                            },
                            [_vm._v("是")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: false },
                              model: {
                                value: _vm.elForm.typeShow,
                                callback: function($$v) {
                                  _vm.$set(_vm.elForm, "typeShow", $$v)
                                },
                                expression: "elForm.typeShow"
                              }
                            },
                            [_vm._v("否")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.elForm.preTypeId && _vm.qnToken
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "分类图标：", prop: "typeIcon" } },
                        [
                          _c(
                            "el-upload",
                            _vm._b(
                              {
                                attrs: {
                                  "on-success": function(res) {
                                    return _vm.handleSuccess(res, "typeIcon")
                                  },
                                  "before-upload": _vm.beforeUpload
                                }
                              },
                              "el-upload",
                              _vm.uploadData,
                              false
                            ),
                            [
                              _vm.elForm.typeIcon
                                ? _c("el-image", {
                                    staticStyle: { height: "100px" },
                                    attrs: {
                                      src: _vm.elForm.typeIcon,
                                      fit: "fill"
                                    }
                                  })
                                : _c(
                                    "el-button",
                                    { attrs: { icon: "el-icon-upload" } },
                                    [_vm._v("选择上传文件")]
                                  )
                            ],
                            1
                          ),
                          _c("p", [
                            _vm._v(
                              "图标尺寸为100*100 比例，大小不能超过200KB，图片只能为jpg、png、gif格式"
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "d2-p-10 d2-pl d2-pr",
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("提交")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }