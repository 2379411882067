var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { width: "100%" }, attrs: { shadow: "never" } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("span", [_vm._v("部门")]),
          _c(
            "el-button",
            {
              staticStyle: { float: "right", padding: "3px 0" },
              attrs: { type: "text" },
              on: { click: _vm.clickAddBuMen }
            },
            [_vm._v("新增部门")]
          )
        ],
        1
      ),
      _c("el-tree", {
        attrs: {
          props: _vm.props,
          data: _vm.data,
          "node-key": "departmentId",
          "default-expand-all": "",
          "expand-on-click-node": false,
          "render-content": _vm.renderContent
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }