import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
import d2Input from '@/components/d2-search/components/d2-input.vue';
import d2TimeSelect from '@/components/d2-search/components/d2-time-select.vue';
import d2Number from '@/components/d2-search/components/d2-number.vue';
import d2Cascader from '@/components/d2-search/components/d2-cascader.vue';
import d2Select from '@/components/d2-search/components/d2-select.vue';
export default {
  components: {
    d2Input: d2Input,
    d2Number: d2Number,
    d2Cascader: d2Cascader,
    d2Select: d2Select,
    d2TimeSelect: d2TimeSelect
  },
  props: {
    tableData: Array,
    tableProp: Array
  },
  data: function data() {
    return {
      head: {
        goodsPrice: '',
        goodsWeight: '',
        goodsStock: ''
      }
    };
  },
  filters: {
    spliceLabel: function spliceLabel(val) {
      var index = val.indexOf('（可不填）');
      var txt = cloneDeep(val);

      if (index > 0) {
        return txt.replace('（可不填）', '');
      } else {
        return txt;
      }
    }
  },
  methods: {
    input: function input(e) {
      console.log(e);
    },
    isInput: function isInput(key, l) {
      return key.indexOf(l.prop) > -1;
    },
    allClick: function allClick(l) {
      var _this = this;

      var data = cloneDeep(this.tableData);
      data.forEach(function (v) {
        _this.$set(v, l, _this.head[l]);
      });
      this.$emit('update:tableData', data);
    },
    callClick: function callClick(l) {
      var _this2 = this;

      var data = cloneDeep(this.tableData);
      data.forEach(function (v) {
        !v[l] && _this2.$set(v, l, _this2.head[l]);
      });
      this.$emit('update:tableData', data);
    }
  }
};