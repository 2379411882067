//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import d2Input from '@/components/d2-search/components/d2-input.vue';
export default {
  components: {
    d2Input: d2Input
  },
  props: {
    tableData: Array,
    tableProp: Array
  }
};