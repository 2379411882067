var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    {
      attrs: {
        effect: "dark",
        content: _vm.tooltipContent,
        placement: "bottom"
      }
    },
    [
      _c(
        "el-button",
        {
          staticClass: "d2-ml-0 d2-mr btn-text can-hover",
          attrs: { type: "text" },
          on: { click: _vm.handleClick }
        },
        [
          _vm.logLength > 0
            ? _c(
                "el-badge",
                {
                  attrs: {
                    max: 99,
                    value: _vm.logLength,
                    "is-dot": _vm.logLength === 0
                  }
                },
                [
                  _c("el-image", {
                    staticStyle: { width: "19.6px", height: "24px" },
                    attrs: { src: _vm.messageImg }
                  })
                ],
                1
              )
            : _c("el-image", {
                staticStyle: { width: "19.6px", height: "24px" },
                attrs: { src: _vm.messageImg }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }